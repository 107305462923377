'use client'

import { useState, useEffect } from 'react'

export default () => {
  const [windowDimension, setWindowDimension] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [])

  return windowDimension
}
