import { ContentfulLocale } from '../../types'
import { TypeAnchor } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, mixed, object, string } from 'yup'

export const anchorSchema = object({
  name: string(),
  title: string(),
  bio: string(),
  twitter: string(),
  instagram: string(),
  facebook: string(),
  email: string(),
  avatar: mixed(),
  regions: mixed()
})

type TAnchorSchema = InferType<typeof anchorSchema>

export const anchorContentfulSchema = createContentfulSchema<TAnchorSchema>(anchorSchema)

export const validateAnchor =
  createSchemaValidator<TypeAnchor<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(anchorContentfulSchema)
