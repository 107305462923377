import regions from '../../config/regions'
import fetchContentful from '../../core/fetchContentful'
import { ContentfulLocale } from '../../types'
import { TypeWeatherConditions } from '../../types/contentful'
import weatherConditionsFieldsSelector from '../selectors/weatherConditionsFieldsSelector'

import { JsonObject, JsonValue } from 'type-fest'

export const paramsForWeatherConditions = (regionId: string) => ({
  content_type: 'weatherConditions',
  'fields.region.sys.id[in]': regionId,
  include: '1',
  select: weatherConditionsFieldsSelector
})

export type WeatherConditions = {
  iconCode: number
  temperature: number
}

const isValidWeatherConditions = (conditions: JsonObject | readonly JsonValue[]): conditions is WeatherConditions =>
  'iconCode' in conditions &&
  'temperature' in conditions &&
  typeof conditions.iconCode === 'number' &&
  typeof conditions.temperature === 'number'

const fetchWeatherConditions = async (regionName: string): Promise<WeatherConditions | null> => {
  const regionId = regions[regionName]?.contentfulId || regions.default.contentfulId
  const weatherConditionsEntries = await fetchContentful<
    TypeWeatherConditions<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]
  >(paramsForWeatherConditions(regionId))

  const conditions = weatherConditionsEntries[0]?.fields.conditions ?? {}

  if (!isValidWeatherConditions(conditions)) {
    return null
  }

  return {
    iconCode: conditions.iconCode,
    temperature: conditions.temperature
  }
}

export default fetchWeatherConditions
