import { useSubdomainContext } from '../components/SubdomainContext/SubdomainContext'
import REGIONS from '../config/regions'
import regions from '../config/regions'

export default () => {
  let { subdomain, domain, port, isReadyOnClientSide } = useSubdomainContext()

  if (!regions[subdomain]) {
    domain = `${[subdomain, domain].filter(p => p).join('.')}:${port}`.replace(/:(null|undefined)?$/, '')
  }

  return Object.assign({ domain, isReadyOnClientSide }, regions[subdomain] || regions.default)
}
export const validRegion = (region: string): boolean => (regions[region]?.name || REGIONS.default) !== REGIONS.default
