import { ContentfulLocale } from '../../types'
import { TypePlatform } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, mixed, object, string } from 'yup'

export const platformSchema = object({
  name: string().required(),
  url: string().required(),
  logo: mixed().required(),
  background: string().required(),
  logoHover: mixed().required(),
  backgroundColorHover: string().required()
})

type TPlatformSchema = InferType<typeof platformSchema>

export const platformContentfulSchema = createContentfulSchema<TPlatformSchema>(platformSchema)

export const validatePlatform =
  createSchemaValidator<TypePlatform<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(platformContentfulSchema)
