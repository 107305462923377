import { ContentfulLocale } from '../../types'
import { TypeAuthor } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { boolean, InferType, mixed, object, string } from 'yup'

export const authorSchema = object({
  byline: string().required(),
  position: string().nullable(),
  slug: string().required(),
  email: string().nullable(),
  active: boolean(),
  avatar: mixed(),
  linkedin: string().nullable(),
  twitter: string().nullable(),
  instagram: string().nullable(),
  bio: string().nullable()
})

type TAuthorSchema = InferType<typeof authorSchema>

export const authorContentfulSchema = createContentfulSchema<TAuthorSchema>(authorSchema)

export const validateAuthor =
  createSchemaValidator<TypeAuthor<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(authorContentfulSchema)
