'use client'

import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google'

import { GA_ID, GTM_ID } from '../../config/analytics'
import { useSubdomainContext } from '../SubdomainContext/SubdomainContext'

const isEmbeddable = (host: string) => {
  const filteredPath = host.split('?')[0] || ''
  return filteredPath === '/poll' || filteredPath === '/minute'
}

const Analytics = () => {
  const { host } = useSubdomainContext()

  if (isEmbeddable(host)) {
    return null
  }

  return (
    <>
      <GoogleAnalytics gaId={GA_ID} />
      <GoogleTagManager gtmId={GTM_ID} />
    </>
  )
}

export default Analytics
