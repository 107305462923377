'use client'

import { useCallback, useContext, useRef } from 'react'

import { ModalContext } from './ModalProvider'
import { ModalName } from './types'

import { v4 as uuidv4 } from 'uuid'

type useModalReturn = [(props: Record<string, any>) => void, () => void]

const useModal = (name: ModalName): useModalReturn => {
  const idRef = useRef<string>(uuidv4())
  const id = idRef.current

  const { openModal, closeModal } = useContext(ModalContext)

  const open = useCallback((props: Record<string, any>) => openModal({ id, name, props }), [openModal])
  const close = useCallback(() => closeModal(id), [closeModal])

  return [open, close]
}

export default useModal
