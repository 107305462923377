'use client'

import React from 'react'

import styled from 'styled-components'

export const StyledMinutelyContainer = styled.div`
  width: 100%;
    
  > * {
    box-sizing: border-box;
  }
  
  .editor-tv-wrapper {
    padding: 0 !important;
    max-width: none !important;
  }
  
  .heading {
    font-size: ${props => props.theme.typography.text.medium.fontSize};
    font-weight: ${props => props.theme.typography.fontWeight.semibold};
    text-transform: uppercase;
    margin-top: 0;
    position: relative;
    padding-left: 11px;
    color: ${props => props.theme.colors.categoryTextColor};

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 5px;
      height: calc(100% - 4px);
      background-color: ${props => props.theme.colors.skyBlue};
    }
  }
`

const MinutelyWidget = () => {
  return (
    <StyledMinutelyContainer>
      <div className='minute-top-video-container' id='minute-top-video-container' />
    </StyledMinutelyContainer>
  )
}

export default MinutelyWidget
