const internalApiQueryWrapper = async <T = any>(path: string, params: Record<string, string | null>): Promise<T> => {
  const url = new URL(path, document.location.toString())

  Object.keys(params).forEach(key => url.searchParams.append(key, params[key] as string))

  const stream = await fetch(url)

  return await stream.json()
}

export default internalApiQueryWrapper
