import { contentfulBaseSchema } from '../../data/validators/commonSchemas'

import { AnyObject, object, ObjectSchema } from 'yup'

export const createContentfulSchema = <T extends AnyObject>(schema: ObjectSchema<T>) => {
  return contentfulBaseSchema.concat(
    object({
      fields: schema.default(undefined).required()
    })
  )
}

export type CreateSchemaValidatorOptions = {
  silent?: boolean
}

export const createSchemaValidator = <T>(
  schema: ObjectSchema<AnyObject>,
  defaultOptions?: CreateSchemaValidatorOptions
) => {
  return (target?: T, options = defaultOptions) => {
    if (!target) return null

    try {
      return schema.validateSync(target)
    } catch (err) {
      if (!options?.silent) {
        console.log(err)
      }

      return null
    }
  }
}
