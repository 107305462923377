'use client'

import { createContext, useContext, useEffect, useState, ReactNode } from 'react'

export interface Subdomain {
  subdomain: string
  cookieDomain: string
  domain: string
  port: string
  host: string
  hostname: string
  path: string
  protocol: string
  isReadyOnClientSide: boolean
}

const defaultSubdomainContext: Subdomain = {
  subdomain: 'default',
  cookieDomain: '',
  domain: '',
  port: '',
  host: '',
  hostname: '',
  path: '',
  protocol: '',
  isReadyOnClientSide: false
}

export const SubdomainContext = createContext<Subdomain>(defaultSubdomainContext)

export const useSubdomainContext = () => {
  return useContext(SubdomainContext)
}

export const SubdomainContextProvider = ({ children }: { children: ReactNode }) => {
  if (typeof window === 'undefined') {
    return children
  }
  const [subdomainContextInfo, setSubdomainContextInfo] = useState<Subdomain>(defaultSubdomainContext)

  useEffect(() => {
    const { host, hostname, port, pathname: path, protocol } = window.location
    const [subdomain, ...rest] = hostname.split('.')
    const domain = rest.join('.')
    const cookieDomain = domain === 'localhost' ? `${subdomain ? `${subdomain}.` : ''}${domain}` : domain

    setSubdomainContextInfo({
      subdomain,
      domain,
      port,
      host,
      hostname,
      path,
      protocol,
      cookieDomain,
      isReadyOnClientSide: true
    })
  }, [])

  return <SubdomainContext.Provider value={subdomainContextInfo}>{children}</SubdomainContext.Provider>
}
