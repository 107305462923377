'use client'

import {
  BottomLinks,
  ButtonProps,
  DesktopNavigation,
  DesktopOnly,
  MobileOnly,
  TopNavigation,
  MobileNavigation,
  NavigationContainer,
  NavigationSlideAnimation,
  NOT_AVAILABLE_ICON_CODE,
  Heading,
  LiveIcon,
  Size,
  TopNavigationTooltip,
  BreakingNewsBannerProps,
  HorizontalList
} from '@cheddartv/storybook-news'

import React, { useEffect, useMemo, useState } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import { CookiesKeys } from '../../config/cookies'
import REGIONS, { Region } from '../../config/regions'
import { NewslettersList } from '../../data/query/fetchNewslettersList'
import { WeatherConditions } from '../../data/query/fetchWeatherConditions'
import { MarketingNavigationItem } from '../../data/transformers/extractNavigation'
import useRegion from '../../hooks/useRegion'
import { MainNavigationSection } from '../../types'
import { oneMonthFromNow } from '../../utils/oneMonthFromNow'
import BreakingNewsBanner from '../CarouselBanner/BreakingNewsBanner'
import useNewsletter from '../Newsletter/useNewsletter'
import { useSubdomainContext } from '../SubdomainContext/SubdomainContext'

import ClientCookies from 'js-cookie'
import { useTheme } from 'styled-components'

export type NavigationProps = {
  navItems: MainNavigationSection[]
  marketingNavItems: MarketingNavigationItem[]
  newslettersList: NewslettersList
  weatherConditions: WeatherConditions | null
  banner: BreakingNewsBannerProps
}

const ONE_SECOND = 1000
const REGION_SWITCHER_TOOLTIP_LIFESPAN = 10 * ONE_SECOND

const Navigation = ({ navItems, newslettersList, marketingNavItems, weatherConditions, banner }: NavigationProps) => {
  const theme = useTheme()

  const region = useRegion()
  const { protocol, port, cookieDomain } = useSubdomainContext()
  const formatRegion = (regionName: string) => ({
    id: regionName,
    name: REGIONS[regionName].longName,
    href: `${protocol}//${[regionName]}.${region.domain}${port ? `:${port}` : ''}/?region=${regionName}`
  })
  const regions = Object.keys(REGIONS)
    .filter(regionName => regionName !== Region.DEFAULT && regionName !== Region.NEW_YORK)
    .map(formatRegion)

  const selectedRegion = regions.find(r => r.id === region.name) || formatRegion(Region.DEFAULT)

  const regionProps = {
    heading: 'Choose Your Region',
    onChange: reg => {
      reg.name && trackGTMEvent.gaRegionSwitchClick(reg.name)
      reg.href && (window.location.href = reg.href)
    },
    selectedRegion,
    regions
  }

  const mobileRegionSelectorProps = useMemo(
    () => ({
      onSelect: regionProps.onChange,
      selectedRegion: selectedRegion,
      regionSelectionModalProps: {
        selectedRegion: selectedRegion,
        heading: regionProps.heading,
        regions
      }
    }),
    [regionProps]
  )

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const { errorMessage, handleEmailChange, handleSubscribeClick } = useNewsletter(newslettersList)

  useEffect(() => {
    let cleanup = () => {}
    if (ClientCookies.get(CookiesKeys.REGION_REDIRECT) === '0') {
      setTooltipOpen(true)
      trackGTMEvent.gaRegionSwitchTooltipShown()
      const timer = setTimeout(() => {
        setTooltipOpen(false)
        ClientCookies.remove(CookiesKeys.REGION_REDIRECT, { domain: cookieDomain })
        ClientCookies.set(CookiesKeys.REGION_REDIRECT, '1', { expires: oneMonthFromNow, domain: cookieDomain })
      }, REGION_SWITCHER_TOOLTIP_LIFESPAN)
      cleanup = () => clearTimeout(timer)
    }

    return cleanup
  }, [cookieDomain])

  const handleBurgerButtonClick = () => {
    setIsOpen(open => !open)
    trackGTMEvent.gaBurgerButtonClick(isOpen)
  }

  const menu =
    navItems?.map((navItem, index) => {
      return {
        id: String(index),
        heading: navItem.title,
        onClick: trackGTMEvent.gaMainMenuLinkClick,
        options:
          navItem?.items?.map(sectionItem => ({
            name: sectionItem.title,
            href: sectionItem.path
          })) || []
      }
    }) || []

  const newsletter = {
    description: 'Topics you care about, straight to your inbox',
    errorMessage: errorMessage,
    heading: 'Be the first to know',
    onChange: handleEmailChange,
    onSubmit: handleSubscribeClick
  }
  const homeUrl = '/'
  const weatherProps = {
    iconCode: weatherConditions?.iconCode ?? Number(NOT_AVAILABLE_ICON_CODE),
    temperature: weatherConditions?.temperature,
    href: '/weather',
    onClick: trackGTMEvent.gaWeatherIconClick
  }

  const buttonProps: ButtonProps = {
    variant: 'primary',
    size: Size.Regular,
    backgroundColor: theme.colors.sirenRed,
    hoverColor: theme.colors.sirenRedShade,
    ariaLabel: 'Go Live',
    children: (
      <>
        <LiveIcon color={theme.colors.white} />
        <Heading tag='h6' uppercase fontWeight='bold' color={theme.colors.white}>
          Live
        </Heading>
      </>
    ),
    href: '/live',
    uppercased: true,
    isSymmetricalPadding: true
  }

  const mainSectionItems = navItems[0]?.items || []
  const horizontalListProps = {
    onClick: trackGTMEvent.gaNavbarClick,
    options: mainSectionItems.map(item => ({
      name: item.title,
      href: item.path
    }))
  }

  const searchProps = {
    onSearch: e => {
      e.preventDefault()
      const { search } = Object.fromEntries(new FormData(e.target))
      trackGTMEvent.gaSearchClick(search)
      window.location.href = `/search?q=${search}`
    }
  }

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <NavigationContainer open={isOpen}>
      {banner?.title ? <BreakingNewsBanner {...banner} /> : null}
      <DesktopOnly>
        <BottomLinks options={marketingNavItems} />
      </DesktopOnly>
      <TopNavigation
        region={regionProps}
        isOpen={isOpen}
        burgerButtonProps={{ isOpen, onClick: handleBurgerButtonClick }}
        searchProps={searchProps}
        weatherProps={weatherProps}
        horizontalListProps={horizontalListProps}
        buttonProps={buttonProps}
        homeUrl={homeUrl}
      />
      {tooltipOpen && !isOpen && <TopNavigationTooltip />}
      {!isOpen && (
        <MobileOnly breakpoint={theme.breakpoints.xl}>
          <HorizontalList {...horizontalListProps} />
        </MobileOnly>
      )}
      <DesktopOnly>
        <NavigationSlideAnimation open={isOpen} data-testid={isOpen && 'navigation-sidebar-desktop'}>
          <DesktopNavigation menu={menu} newsletter={newsletter} region={regionProps} />
        </NavigationSlideAnimation>
      </DesktopOnly>
      <MobileOnly>
        <NavigationSlideAnimation open={isOpen} data-testid={isOpen && 'navigation-sidebar-mobile'}>
          <MobileNavigation
            menu={menu}
            secondaryMenu={marketingNavItems}
            regionSelectorProps={mobileRegionSelectorProps}
            searchProps={searchProps}
            newsletter={newsletter}
          />
        </NavigationSlideAnimation>
      </MobileOnly>
    </NavigationContainer>
  )
}

export default Navigation
