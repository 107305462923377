'use client'

import { useCallback, useContext } from 'react'
import { createPortal } from 'react-dom'

import { ModalContext } from './ModalProvider'
import modalComponents from './modalComponents'
import { ModalName } from './types'

import styled from 'styled-components'

type ModalWrapper = {
  id: string
  name: ModalName
  props: any
}

// Temporary fix for legacy pages
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;

  h2, h3 {
    border: none;
    padding-bottom: 0;
  }
`

const ModalWrapper = ({ id, name, props }: ModalWrapper) => {
  const { closeModal } = useContext(ModalContext)
  const Component = modalComponents[name]

  const handleClose = useCallback(() => {
    closeModal(id)
  }, [closeModal, id])

  return createPortal(
    <Wrapper>
      <Component onClose={handleClose} {...props} />
    </Wrapper>,
    document.body
  )
}

export default ModalWrapper
