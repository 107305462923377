import useBelowBreakpoint from '../../hooks/useBelowBreakpoint'

const useSlidesToScroll = () => {
  const isBelowXxlScreen = useBelowBreakpoint('xxl')
  const isBelowLgScreen = useBelowBreakpoint('lg')

  if (isBelowLgScreen) {
    return 2
  } else if (isBelowXxlScreen) {
    return 3
  } else {
    return 4
  }
}

export default useSlidesToScroll
