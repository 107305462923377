import { ContentfulLocale, MainNavigationSection } from '../../types'
import {
  isTypeNews12NewYork,
  TypeCollectionSkeleton,
  TypeHomepageSkeleton,
  TypeListSkeleton,
  TypeLivePageSkeleton,
  TypeMenu,
  TypeNavigation,
  TypeNews12NewYorkSkeleton,
  TypePageSkeleton,
  TypeStorySkeleton,
  TypeWeatherConditionsSkeleton
} from '../../types/contentful'
import { NavigationLinksObjectKeys, NavigationLinksObjectSection } from '../../types/contentful/TypeNavigation'
import { isValidLinksObjectItem } from '../../types/linksObject'

import { Entry } from 'contentful'

const getPath = entry => {
  switch (entry.sys.contentType.sys.id) {
    case 'collection':
      return `/category/${entry.fields?.slug}`

    case 'page':
      return `/pages/${entry.fields?.slug}`

    case 'news12NewYork':
      return `https://newyork.news12.com`

    default:
      return `/${entry.fields?.slug || ''}`
  }
}

export type FooterField = {
  path: string
  name: string
  isRoot?: boolean
}

export type NavigationFooter = {
  contacts: FooterField[]
  affliates: FooterField[]
  about: FooterField[]
}

export type NavigationData = {
  header: MainNavigationSection[]
  footer: NavigationFooter
  entryIDs?: (string | undefined)[]
}

type SkeletonsWithTitle =
  | TypeCollectionSkeleton
  | TypeHomepageSkeleton
  | TypeListSkeleton
  | TypeLivePageSkeleton
  | TypePageSkeleton
  | TypeStorySkeleton
  | TypeWeatherConditionsSkeleton
type EntriesWithTitle = Entry<SkeletonsWithTitle, 'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>
type SkeletonsWithPageTitle = TypeNews12NewYorkSkeleton
type Link = Entry<SkeletonsWithTitle | SkeletonsWithPageTitle, 'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>

const getTitle = (link: Link): string => {
  if (isTypeNews12NewYork(link)) {
    return (link.fields?.pageTitle || '') as string
  } else {
    return (link as EntriesWithTitle).fields?.title ?? ''
  }
}

const DEFAULT_NAVIGATION_FOOTER: NavigationData['footer'] = {
  contacts: [],
  affliates: [],
  about: []
}

export const extractFooter = (objectLink: Record<NavigationLinksObjectKeys, NavigationLinksObjectSection>) => {
  return {
    contacts: Object.entries(objectLink.contacts || {}).map(([key, value]) => ({
      name: key,
      path: value.path || '',
      isRoot: value.isRoot || false
    })),
    affliates: Object.entries(objectLink.affliates || {}).map(([key, value]) => ({
      name: key,
      path: value.path || '',
      isRoot: value.isRoot || false
    })),
    about: Object.entries(objectLink.about || {}).map(([key, value]) => ({
      name: key,
      path: value.path || '',
      isRoot: value.isRoot || false
    }))
  }
}

const extractNavigation = (data: TypeMenu<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]): NavigationData => {
  const entryIDs: (string | undefined)[] = []

  const header =
    data?.[0]?.fields.links
      ?.filter(tabItem => {
        entryIDs.push(tabItem?.sys?.id)

        return tabItem?.fields?.links
      })
      .map(tabItem => {
        return {
          title: tabItem?.fields?.title ?? '',
          items:
            tabItem?.fields?.links?.map(menuItem => ({
              title: getTitle(menuItem!),
              path: getPath(menuItem)
            })) ?? []
        }
      }) ?? []

  const footer =
    (data[0]?.fields?.links
      ?.map(tabItem => tabItem?.fields?.linksObject)
      ?.filter((footer): footer is Record<NavigationLinksObjectKeys, NavigationLinksObjectSection> => Boolean(footer))
      ?.map(footer => extractFooter(footer))
      ?.find(footer => footer) as NavigationFooter) || DEFAULT_NAVIGATION_FOOTER

  return { header, footer, entryIDs }
}

export type MarketingNavigationItem = {
  name: string
  href: string
}

export const extractMarketingNavigation = (
  data: TypeNavigation<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]
): MarketingNavigationItem[] => {
  const result: MarketingNavigationItem[] = []

  const links = data[0]?.fields.links ?? []

  for (const item of links) {
    if (item) {
      result.push({
        name: getTitle(item),
        href: getPath(item)
      })
    }
  }

  const linksObject = data[0]?.fields.linksObject ?? {}

  for (const [name, item] of Object.entries(linksObject)) {
    if (isValidLinksObjectItem(item)) {
      result.push({
        name,
        href: item.path
      })
    }
  }

  return result
}

export default extractNavigation
