import { StatusMessageModalStatus } from '@cheddartv/storybook-news'

import { useCallback, useState } from 'react'

import internalApiQueryWrapper from '../../core/internalApiQueryWrapper'
import { NewslettersList } from '../../data/query/fetchNewslettersList'
import { ModalName } from '../modal/types'
import useModal from '../modal/useModal'
import { validateEmail } from './utils/validateEmail'

const serializeSelectedNewsletters = ({
  newslettersList,
  email
}: {
  newslettersList: NewslettersList
  email: string
}) => {
  const lists = newslettersList.filter(list => list.checked).reduce((acc, list) => ({ ...acc, [list.name]: 1 }), {})

  return {
    email,
    lists: JSON.stringify(lists)
  }
}

export const NewsletterFieldErrorMessage = {
  REQUIRED: 'Oops, email is required!',
  INVALID: 'Oops, email address is invalid!'
}

type SubscriptionSuccess = {
  ok: true
}

const useNewsletter = (newslettersList: NewslettersList) => {
  const [openNewsletterModal, closeNewsletterModal] = useModal(ModalName.NEWSLETTER_MODAL)
  const [openStatusMessageModal] = useModal(ModalName.STATUS_MESSAGE_MODAL)

  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailChange = useCallback(({ target }) => setEmail(target.value), [])

  const handleSubscription = useCallback(
    async (newslettersList: NewslettersList) => {
      const { ok } = await internalApiQueryWrapper<SubscriptionSuccess>(
        '/api/newsletter/subscribe',
        serializeSelectedNewsletters({ newslettersList, email })
      )
      const statusModalConfig = ok
        ? {
            status: StatusMessageModalStatus.SUCCESS,
            title: 'All set, thanks for signing up!'
          }
        : {
            status: StatusMessageModalStatus.ERROR,
            title: 'Oops, something went wrong!'
          }

      closeNewsletterModal()
      openStatusMessageModal(statusModalConfig)
    },
    [email, openStatusMessageModal, closeNewsletterModal]
  )

  const handleSubscribeClick = useCallback(() => {
    if (validateEmail(email)) {
      setErrorMessage('')
      openNewsletterModal({
        title: 'Please select topics you want to be informed about',
        subscriptions: newslettersList,
        onSubmit: handleSubscription
      })
    } else {
      setErrorMessage(email ? NewsletterFieldErrorMessage.INVALID : NewsletterFieldErrorMessage.REQUIRED)
    }
  }, [email, openNewsletterModal])

  return {
    errorMessage,
    email,
    handleEmailChange,
    handleSubscribeClick
  }
}

export default useNewsletter
