import { default as TRegion } from '../data/types/region'

export enum Region {
  DEFAULT = 'default',
  BRONX = 'bronx',
  BROOKLYN = 'brooklyn',
  CONNECTICUT = 'connecticut',
  HUDSON_VALLEY = 'hudsonvalley',
  LONG_ISLAND = 'longisland',
  NEW_JERSEY = 'newjersey',
  WESTCHESTER = 'westchester',
  NEW_YORK = 'newyork'
}

const REGIONS: Record<Region, TRegion> = {
  [Region.DEFAULT]: {
    name: Region.DEFAULT,
    shortName: '',
    longName: 'Default',
    zip: '11717',
    adPath: 'news12.com/LI',
    videoAdPath: 'video.news12.com/LI',
    liveVideoAdPath: 'video.news12.com/LI/live-video',
    topStoriesId: '4jxAhf4NqSyIRTZ5i4ktlu',
    contentfulHomepageId: 'xJumthEWea2A5LO1Ahmhk',
    contentfulId: '5Fh5vPC7p0LsYyI9RfLA4I',
    contentfulNavId: '1PfgvbUjX596vMSP9ox7En',
    contentfulMarketingNavId: '2fWlyHSHbQ0HkhFKgBtb6Z',
    nav: {
      main: {
        News: { path: '/category/long-island-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Vote 2022': { path: '/category/island-vote-2022', isRoot: false },
        Crime: { path: '/category/long-island-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/long-island-politics', isRoot: false },
          Sports: { path: '/category/long-island-sports', isRoot: false },
          'Food & Fun': { path: '/category/long-island-food-and-fun', isRoot: false },
          Environment: { path: '/category/long-island-environment', isRoot: false },
          'Numbers & Links': { path: '/category/long-island-numbers-and-links', isRoot: false },
          Transportation: { path: '/category/long-island-transportation', isRoot: false },
          'State of Our Schools': {
            path: '/category/long-island-state-of-our-schools',
            isRoot: false
          },
          'N12 Events': { path: '/pages/long-island-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/long-island-breast-cancer-awareness',
            isRoot: false
          },
          'Island Vote 2022': { path: '/category/island-vote-2022', isRoot: false },
          'Road Trip: Close to Home': {
            path: '/category/long-island-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          '12 Making a Difference': {
            path: '/category/long-island-12-making-a-difference',
            isRoot: false
          },
          'The East End': { path: '/category/eastend', isRoot: false },
          'Just Engaged on Long Island': {
            path: '/just-engaged-on-long-island-41609174',
            isRoot: true
          },
          'Just Married on Long Island': {
            path: '/just-married-on-long-island-41505203',
            isRoot: true
          },
          'Paws & Pals': { path: '/category/long-island-paws-and-pals', isRoot: false },
          "What's Cooking": { path: '/category/long-island-whats-cooking', isRoot: false },
          'Birthday Smiles': { path: '/long-island-birthday-smiles', isRoot: true },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Scholar Athlete': { path: '/category/long-island-scholar-athlete', isRoot: false },
          'Marijuana Matters': { path: '/category/long-island-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/long-island-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/li-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Mighty Mite Monday Night': { path: '/category/mighty-mite-monday-night', isRoot: false },
          'Hometown Heroes': { path: '/lihometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30510',
      embedId: '1051306'
    }
  },
  [Region.BRONX]: {
    name: Region.BRONX,
    shortName: 'bx',
    longName: 'The Bronx',
    zip: '10451',
    adPath: 'news12.com/BX',
    videoAdPath: 'video.news12.com/BX',
    liveVideoAdPath: 'video.news12.com/BX/live-video',
    topStoriesId: '5l6Y9ROeDp2BifgteY1rSp',
    contentfulHomepageId: '30JazZqGemYPdvl82SZZhw',
    contentfulId: '67ToDy2u8uXYg6Fj4qZWgH',
    contentfulNavId: '4aDKC1xte5tL9zIs5WHH23',
    contentfulMarketingNavId: '3IU3EsIqsRcVpog1pbYuHC',
    nav: {
      main: {
        News: { path: '/category/bronx-news', isRoot: false },
        Weather: { path: '/weather', isRoot: true },
        Investigative: {
          path: '/category/team-12-investigates',
          isRoot: false,
          name: 'Team 12 Investigates'
        },
        'Vote 2022': { path: '/category/bx-vote-2022', isRoot: false },
        Crime: { path: '/category/bronx-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/bronx-politics', isRoot: false },
          Sports: { path: '/category/bronx-sports', isRoot: false },
          Transportation: { path: '/category/bronx-transportation', isRoot: false },
          'Food & Fun': { path: '/category/bronx-food-and-fun', isRoot: false },
          'State of Our Schools': { path: '/category/bronx-state-of-our-schools', isRoot: false },
          'Numbers & Links': { path: '/category/bronx-numbers-and-links', isRoot: false },
          'N12 Events': { path: '/pages/bronx-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Scholar Athlete': { path: '/category/the-bronx-scholar-athlete', isRoot: false },
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/bronx-breast-cancer-awareness',
            isRoot: false
          },
          'Vote 2022': { path: '/category/bx-vote-2022', isRoot: false },
          'Road Trip: Close to Home': {
            path: '/category/bronx-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          'Just Engaged in The Bronx': {
            path: '/just-engaged-in-the-bronx-41615625',
            isRoot: true
          },
          'Just Married in The Bronx': {
            path: '/just-married-in-the-bronx-41505359',
            isRoot: true
          },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Birthday Smiles': { path: '/bronx-birthday-smiles-41505091', isRoot: true },
          'Marijuana Matters': { path: '/category/bronx-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/bronx-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/nyc-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'High School Spotlight': { path: '/bx-high-school-spotlight', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Hometown Heroes': { path: '/nyhometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30530',
      embedId: '1051311'
    }
  },
  [Region.BROOKLYN]: {
    name: Region.BROOKLYN,
    shortName: 'bk',
    longName: 'Brooklyn',
    zip: '11238',
    adPath: 'news12.com/BK',
    videoAdPath: 'video.news12.com/BK',
    liveVideoAdPath: 'video.news12.com/BK/live-video',
    topStoriesId: 'tclNJqU5DyzO0q90mAuwk',
    contentfulHomepageId: '1jfhOySU9bCIPVsHIuekEh',
    contentfulId: '5g2XMQgs47iQJwz6fi9xnf',
    contentfulNavId: '7esOyHihZnfinjBOltG82z',
    contentfulMarketingNavId: '2zeGzx0PACyl8k1verisEh',
    nav: {
      main: {
        News: { path: '/category/brooklyn-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Vote 2022': { path: '/category/bk-vote-2022', isRoot: false },
        Crime: { path: '/category/brooklyn-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/brooklyn-politics', isRoot: false },
          Sports: { path: '/category/brooklyn-sports', isRoot: false },
          Transportation: { path: '/category/brooklyn-transportation', isRoot: false },
          'Food & Fun': { path: '/category/brooklyn-food-and-fun', isRoot: false },
          'State of Our Schools': {
            path: '/category/brooklyn-state-of-our-schools',
            isRoot: false
          },
          'Numbers & Links': { path: '/category/brooklyn-numbers-and-links', isRoot: false },
          'N12 Events': { path: '/pages/brooklyn-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/brooklyn-breast-cancer-awareness',
            isRoot: false
          },
          'Vote 2022': { path: '/category/bk-vote-2022', isRoot: false },
          'Road Trip: Close to Home': {
            path: '/category/brooklyn-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          'Just Engaged in Brooklyn': { path: '/just-engaged-in-brooklyn-41615562', isRoot: true },
          'Just Married in Brooklyn': { path: '/just-married-in-brooklyn-41505392', isRoot: true },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Birthday Smiles': { path: '/brooklyn-birthday-smiles-41505042', isRoot: true },
          'Marijuana Matters': { path: '/category/brooklyn-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/brooklyn-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/nyc-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'High School Spotlight': { path: '/bk-high-school-spotlight', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Hometown Heroes': { path: '/nyhometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30520',
      embedId: '1051308'
    }
  },
  [Region.CONNECTICUT]: {
    name: Region.CONNECTICUT,
    shortName: 'ct',
    longName: 'Connecticut',
    zip: '06850',
    adPath: 'news12.com/CT',
    videoAdPath: 'video.news12.com/CT',
    liveVideoAdPath: 'video.news12.com/CT/live-video',
    topStoriesId: 'tdo7Te37LfbM0NLYPfbmr',
    contentfulHomepageId: '2USd9VvuvWBqiisZ79b8D',
    contentfulId: '64Yt6apvEZsviUQbS4GCsc',
    contentfulNavId: 'aeisShrfllylruhodG0Cd',
    contentfulMarketingNavId: '2nbV0tm9FNu0dIp1f1vE7i',
    nav: {
      main: {
        News: { path: '/category/connecticut-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Sandy: 10 Years Later': { path: '/projects/sandy', isRoot: false },
        Crime: { path: '/category/connecticut-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/connecticut-politics', isRoot: false },
          Sports: { path: '/category/connecticut-sports', isRoot: false },
          'Food & Fun': { path: '/category/connecticut-food-and-fun', isRoot: false },
          Environment: { path: '/category/connecticut-environment', isRoot: false },
          Transportation: { path: '/category/connecticut-transportation', isRoot: false },
          'State of Our Schools': {
            path: '/category/connecticut-state-of-our-schools',
            isRoot: false
          },
          'Numbers & Links': { path: '/category/connecticut-numbers-and-links', isRoot: false },
          'N12 Events': { path: '/pages/connecticut-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/connecticut-breast-cancer-awareness',
            isRoot: false
          },
          'Road Trip: Close to Home': {
            path: '/category/connecticut-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          "Chef's Quick Tips": { path: '/category/connecticut-chefs-quick-tips', isRoot: false },
          'Health Matters': { path: '/category/connecticut-health-matters', isRoot: false },
          'Just Engaged in Connecticut': {
            path: '/just-engaged-in-connecticut-41609195',
            isRoot: true
          },
          'Just Married in Connecticut': {
            path: '/just-married-in-connecticut-41505263',
            isRoot: true
          },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Birthday Smiles': { path: '/connecticut-birthday-smiles-41505016', isRoot: true },
          'Advances in Health': { path: '/category/advancesinhealth', isRoot: false },
          'Our Lives': { path: '/category/our-lives', isRoot: false },
          'Marijuana Matters': { path: '/category/connecticut-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/connecticut-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/ct-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Hometown Heroes': { path: '/cthometownheroes', isRoot: false },
          'Scholar Athlete': { path: 'category/connecticut-scholar-athlete', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30540',
      embedId: '1051304'
    }
  },
  [Region.HUDSON_VALLEY]: {
    name: Region.HUDSON_VALLEY,
    shortName: 'hv',
    longName: 'Hudson Valley',
    zip: '12534',
    adPath: 'news12.com/HV',
    videoAdPath: 'video.news12.com/HV',
    liveVideoAdPath: 'video.news12.com/HV/live-video',
    topStoriesId: '7x306mqvVbedRPepXaLqi3',
    contentfulHomepageId: '4PDK6UzbQjG27uGuoOkqKo',
    contentfulId: '57YhTpGKbXsF2NlnDZfH0',
    contentfulNavId: '5FVOR6c4CgqE21xKpZQh1g',
    contentfulMarketingNavId: '3VXWXJPqPSevqES408aePG',
    nav: {
      main: {
        News: { path: '/category/hudson-valley-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Vote 2022': { path: '/category/hv-vote-2022', isRoot: false },
        Crime: { path: '/category/hudson-valley-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/hudson-valley-politics', isRoot: false },
          Sports: { path: '/category/hudson-valley-sports', isRoot: false },
          'Food & Fun': { path: '/category/hudson-valley-food-and-fun', isRoot: false },
          'State of Our Schools': {
            path: '/category/hudson-valley-state-of-our-schools',
            isRoot: false
          },
          Environment: { path: '/category/hudson-valley-environment', isRoot: false },
          Transportation: { path: '/category/hudson-valley-transportation', isRoot: false },
          'Numbers & Links': { path: '/category/hudson-valley-numbers-and-links', isRoot: false },
          'N12 Events': { path: '/pages/hudson-valley-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/wchv-breast-cancer-awareness',
            isRoot: false
          },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          'Vote 2022': { path: '/category/hv-vote-2022', isRoot: false },
          'Road Trip: Close to Home': {
            path: '/category/hudson-valley-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Just Engaged in the Hudson Valley': {
            path: '/just-engaged-in-the-hudson-valley-41615270',
            isRoot: true
          },
          'Just Married in the Hudson Valley': {
            path: '/just-married-in-the-hudson-valley-41505331',
            isRoot: true
          },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Birthday Smiles': { path: '/birthday-smiles-2020-hv-41495266', isRoot: true },
          'Marijuana Matters': { path: '/category/hudson-valley-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/hudson-valley-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/hv-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'What’s Cooking': { path: '/category/long-island-whats-cooking', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Hometown Heroes': { path: '/hvhometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      },
      footer: {
        contacts: {
          Newsletter: { path: '/newsletter' },
          'Send Photos/Videos': { path: '/pages/hudson-valley-your-photos', isRoot: true },
          Contact: { path: '/pages/hudson-valley-contact', isRoot: true },
          'About Us': { path: '/pages/about', isRoot: true },
          Talent: { path: '/talent' },
          'News 12 New York': { path: 'https://newyork.news12.com/' }
        },
        affliates: {
          'Altice USA': { path: 'https://www.alticeusa.com' },
          Optimum: { path: 'https://www.optimum.com' },
          'Cheddar News': { path: 'https://cheddar.com' },
          A4: { path: 'https://a4alticeadvertising.com/' },
          Careers: { path: 'https://www.alticeusacareers.com' }
        },
        about: {
          'Privacy Policy': { path: '/pages/privacy-policy', isRoot: true },
          'Terms of Service': { path: '/pages/terms', isRoot: true },
          'Ad Choices': { path: '/pages/privacy-policy', isRoot: true }
        }
      }
    },
    minute: {
      accountId: '30500',
      embedId: '1051305'
    }
  },
  [Region.LONG_ISLAND]: {
    name: Region.LONG_ISLAND,
    shortName: 'li',
    longName: 'Long Island',
    zip: '11717',
    adPath: 'news12.com/LI',
    videoAdPath: 'video.news12.com/LI',
    liveVideoAdPath: 'video.news12.com/LI/live-video',
    topStoriesId: '4jxAhf4NqSyIRTZ5i4ktlu',
    contentfulHomepageId: 'xJumthEWea2A5LO1Ahmhk',
    contentfulId: '5Fh5vPC7p0LsYyI9RfLA4I',
    contentfulNavId: '1PfgvbUjX596vMSP9ox7En',
    contentfulMarketingNavId: '2fWlyHSHbQ0HkhFKgBtb6Z',
    nav: {
      main: {
        News: { path: '/category/long-island-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Vote 2022': { path: '/category/island-vote-2022', isRoot: false },
        Crime: { path: '/category/long-island-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/long-island-politics', isRoot: false },
          Sports: { path: '/category/long-island-sports', isRoot: false },
          'Food & Fun': { path: '/category/long-island-food-and-fun', isRoot: false },
          Environment: { path: '/category/long-island-environment', isRoot: false },
          'Numbers & Links': { path: '/category/long-island-numbers-and-links', isRoot: false },
          Transportation: { path: '/category/long-island-transportation', isRoot: false },
          'State of Our Schools': {
            path: '/category/long-island-state-of-our-schools',
            isRoot: false
          },
          'N12 Events': { path: '/pages/long-island-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/long-island-breast-cancer-awareness',
            isRoot: false
          },
          'Island Vote 2022': { path: '/category/island-vote-2022', isRoot: false },
          'Road Trip: Close to Home': {
            path: '/category/long-island-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          '12 Making a Difference': {
            path: '/category/long-island-12-making-a-difference',
            isRoot: false
          },
          'The East End': { path: '/category/eastend', isRoot: false },
          'Just Engaged on Long Island': {
            path: '/just-engaged-on-long-island-41609174',
            isRoot: true
          },
          'Just Married on Long Island': {
            path: '/just-married-on-long-island-41505203',
            isRoot: true
          },
          'Paws & Pals': { path: '/category/long-island-paws-and-pals', isRoot: false },
          "What's Cooking": { path: '/category/long-island-whats-cooking', isRoot: false },
          'Birthday Smiles': { path: '/long-island-birthday-smiles', isRoot: true },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Scholar Athlete': { path: '/category/long-island-scholar-athlete', isRoot: false },
          'Marijuana Matters': { path: '/category/long-island-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/long-island-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/li-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Mighty Mite Monday Night': { path: '/category/mighty-mite-monday-night', isRoot: false },
          'Hometown Heroes': { path: '/lihometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30510',
      embedId: '1051306'
    }
  },
  [Region.NEW_JERSEY]: {
    name: Region.NEW_JERSEY,
    shortName: 'nj',
    longName: 'New Jersey',
    zip: '07302',
    adPath: 'news12.com/NJ',
    videoAdPath: 'video.news12.com/NJ',
    liveVideoAdPath: 'video.news12.com/NJ/live-video',
    topStoriesId: '6LtYIGl1USdM95KUaSE5s7',
    contentfulHomepageId: '5GjBI6Z79V8InjSOz8ckOD',
    contentfulId: '22cHKtE2AFPCc3BJGwRDiG',
    contentfulNavId: '4ACoavisdK6jkwk5Y7Yr21',
    contentfulMarketingNavId: '4sLygi3Oh3ptY3Wdt1yTfT',
    nav: {
      main: {
        News: { path: '/category/new-jersey-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Vote 2022': { path: '/category/nj-vote-2022', isRoot: false },
        Crime: { path: '/category/new-jersey-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/category/new-jersey-politics', isRoot: false },
          Sports: { path: '/category/new-jersey-sports', isRoot: false },
          'Food & Fun': { path: '/category/new-jersey-food-and-fun', isRoot: false },
          Environment: { path: '/category/new-jersey-environment', isRoot: false },
          Transportation: { path: '/category/new-jersey-transportation', isRoot: false },
          'State of Our Schools': {
            path: '/category/new-jersey-state-of-our-schools',
            isRoot: false
          },
          'Numbers & Links': { path: '/category/new-jersey-numbers-and-links', isRoot: false },
          'N12 Events': { path: '/pages/new-jersey-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/new-jersey-breast-cancer-awareness',
            isRoot: false
          },
          'Road Trip: Close to Home': {
            path: '/category/new-jersey-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          'Positively New Jersey': {
            path: '/category/new-jersey-positively-new-jersey',
            isRoot: false
          },
          'Just Engaged in New Jersey': {
            path: '/just-engaged-in-new-jersey-41615353',
            isRoot: true
          },
          'Just Married in New Jersey': {
            path: '/just-married-in-new-jersey-41505296',
            isRoot: true
          },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'Jersey Proud': { path: '/category/new-jersey-jersey-proud', isRoot: false },
          'Birthday Smiles': { path: '/new-jersey-birthday-smiles-41504912', isRoot: true },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Scholar Athlete': { path: '/category/new-jersey-scholar-athlete', isRoot: false },
          'Marijuana Matters': { path: '/category/new-jersey-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/new-jersey-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/nj-honor-roll', isRoot: false },
          'What’s Cooking': { path: '/category/long-island-whats-cooking', isRoot: false },
          'Spotted Lanternfly: Stomp It Out': {
            path: '/category/spottedlanternflystompitout',
            isRoot: false
          },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Hometown Heroes': { path: '/njhometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30560',
      embedId: '1051307'
    }
  },
  [Region.WESTCHESTER]: {
    name: Region.WESTCHESTER,
    shortName: 'wc',
    longName: 'Westchester',
    zip: '10522',
    adPath: 'news12.com/WC',
    videoAdPath: 'video.news12.com/WC',
    liveVideoAdPath: 'video.news12.com/WC/live-video',
    topStoriesId: 'mr6ONfOivqqnk9gMUtMp4',
    contentfulHomepageId: '6TK8IbFrww8F5xPcVyy82N',
    contentfulId: '1H3vrQbJ0zU8HAcSAA9gMN',
    contentfulNavId: '4J0MjKinr6Ad8C3IybE6pq',
    contentfulMarketingNavId: '4QswIpmibJGM3bAa08rld0',
    nav: {
      main: {
        News: { path: '/category/westchester-news', isRoot: true },
        Weather: { path: '/weather', isRoot: true },
        Investigative: { path: '/category/team-12-investigates', isRoot: false },
        'Vote 2022': { path: '/category/wc-vote-2022', isRoot: false },
        Crime: { path: '/category/westchester-crime', isRoot: false },
        'The Real Deal': { path: '/category/the-real-deal', isRoot: false }
      },
      secondary: {
        Sections: {
          Politics: { path: '/westchester-politics', isRoot: false },
          Sports: { path: '/category/westchester-sports', isRoot: false },
          Environment: { path: '/category/westchester-environment', isRoot: false },
          'Food & Fun': { path: '/category/westchester-food-and-fun', isRoot: false },
          Transportation: { path: '/category/westchester-transportation', isRoot: false },
          'State of Our Schools': {
            path: '/category/westchester-state-of-our-schools',
            isRoot: false
          },
          'Numbers & Links': { path: '/category/westchester-numbers-and-links', isRoot: false },
          'N12 Events': { path: '/pages/westchester-events', isRoot: true },
          'Noticias Univision 41': {
            path: '/category/noticias-univision-41-por-news-12',
            isRoot: false
          }
        },
        Features: {
          'Hispanic Heritage Month': { path: '/category/hispanic-heritage-month', isRoot: false },
          'Breast Cancer Awareness Month': {
            path: '/category/wchv-breast-cancer-awareness',
            isRoot: false
          },
          'Turn to Tara': { path: '/category/turn-to-tara', isRoot: false },
          'Vote 2022': { path: '/category/wc-vote-2022', isRoot: false },
          'Road Trip: Close to Home': {
            path: '/category/westchester-road-trip-close-to-home',
            isRoot: false
          },
          'Kane in Your Corner': { path: '/category/kane-in-your-corner', isRoot: false },
          'Just Engaged in the Hudson Valley': {
            path: '/just-engaged-in-the-hudson-valley-41615270',
            isRoot: true
          },
          'Just Married in the Hudson Valley': {
            path: '/just-married-in-the-hudson-valley-41505331',
            isRoot: true
          },
          'On A Positive Note': { path: '/category/on-a-positive-note', isRoot: false },
          'Birthday Smiles': { path: '/birthday-smiles-2020-hv-41495266', isRoot: true },
          'Marijuana Matters': { path: '/category/westchester-marijuana-matters', isRoot: false },
          'Photo Galleries': { path: '/category/westchester-photo-galleries', isRoot: false },
          'Honor Roll': { path: '/hv-honor-roll', isRoot: false },
          'On the Scene': { path: '/category/new-jersey-on-the-scene', isRoot: false },
          'What’s Cooking': { path: '/category/long-island-whats-cooking', isRoot: false },
          'N12 Originals': { path: '/category/n12-originals', isRoot: false },
          'The New Normal': { path: '/category/the-new-normal', isRoot: false },
          'Team 12 Investigates': { path: '/category/team-12-investigates', isRoot: false },
          'Hometown Heroes': { path: '/hvhometownheroes', isRoot: false },
          'Storm Watch Blog': { path: '/storm-watch-updates', isRoot: false }
        }
      }
    },
    minute: {
      accountId: '30550',
      embedId: '1051313'
    }
  },
  [Region.NEW_YORK]: {
    name: Region.NEW_YORK,
    shortName: 'ny',
    longName: 'New York',
    adPath: 'news12.com',
    videoAdPath: 'video.news12.com',
    liveVideoAdPath: 'video.news12.com/live-video',
    contentfulId: '5kWWYHsgyH8LbR6pWonHpG',
    nav: {
      footer: {
        contacts: {
          Contact: { path: '/pages/newyork-contact', isRoot: true },
          'About Us': { path: '/pages/about', isRoot: true }
        },
        affliates: {
          'Altice USA': { path: 'https://www.alticeusa.com' },
          Optimum: { path: 'https://www.optimum.com' },
          'Cheddar News': { path: 'https://cheddar.com' },
          A4: { path: 'https://a4alticeadvertising.com/' },
          Careers: { path: 'https://www.alticeusacareers.com' }
        },
        about: {
          'Privacy Policy': { path: '/pages/privacy-policy', isRoot: true },
          'Terms of Service': { path: '/pages/terms', isRoot: true },
          'Ad Choices': { path: '/pages/privacy-policy', isRoot: true }
        }
      }
    }
  }
}

export default REGIONS
