'use client'

import resolveImage from '../data/transformers/resolveImage'
import useRegion from '../hooks/useRegion'

import Head from 'next/head'

const Metadata = ({ metadata }) => {
  const region = useRegion()
  const keywords = metadata?.keywords?.join(', ')
  const description = metadata?.description
  const title = metadata?.title
  const image = metadata?.thumbnailUrl
    ? 'https:' + metadata?.thumbnailUrl
    : resolveImage({ url: metadata?.thumbnail?.fields?.file?.url })

  return (
    <Head>
      <meta property='description' content={description} />
      {keywords && <meta property='keywords' content={keywords} />}

      <meta property='og:site_name' content={`News 12 - ${region?.longName}`} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='675' />

      <meta property='twitter:site' content={`@News12${region.shortName.toUpperCase()}`} />
      <meta property='twitter:dnt' content='on' />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image:src' content={image} />
    </Head>
  )
}

export default Metadata
