import theme from '../../../theme/news12'
import redesignedTheme from '../../../theme/news12-redesign'

import css from 'styled-jsx/css'

export const header = css.header`
  header {
    position: sticky;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 99999;  /* Highest video player z-index currently 60 */
  }

  .wrapper {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }

  .top {
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .container-nav-button {
    margin-left: -10px;
    margin-top: -10px;
    flex-basis: 90px;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .open-button:before {
    content: '';
    width: 24px;
    height: 3px;
    background-color: ${theme.colors.mineShaft};
    display: block;
    top: 6px;
    position: relative;
  }

  .open-button {
    width: 24px;
    height: 3px;
    background-color: ${theme.colors.mineShaft};
  }

  .open-button:after {
    content: '';
    width: 24px;
    height: 3px;
    background-color: ${theme.colors.mineShaft};
    display: block;
    top: 9px;
    position: relative;
  }

  .container-logo-primary {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .container-logo-primary img {
    width: 112px;
    height: auto;
    vertical-align: middle;
    padding: 17px 0;
  }

  .container-logo-primary:hover {
    cursor:pointer;
  }

  .weather-container {
    flex-basis: 90px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
 
  .weather-temperature {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: ${redesignedTheme.colors.mineShaft};
  }
  
  .weather-icon {
    width: 32px;
    height: 26px;
    padding-bottom: 3px;
  }

  .social {
    display: none;
    color: #ffffff;
    font-size: 12px;
    width: 143px;
    justify-content: space-between;
  }
  
  .bottom {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
`
