import breakpoints from '../../../theme/breakpoints'

import { global } from 'styled-jsx/css'

export default global`
  .redesigned-footer {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 16px !important;
    
    h3, h6 {
      border: none;
      padding: 0;
    }
    
    h3 {
      line-height: 21px !important;
      font-size: 16px !important;
    }
    
    span {
      line-height: 18px !important;
      font-size: 14px !important;
    }
    
    p, h6 {
      line-height: 18px !important;
      font-size: 14px !important;
    }
  }

  .ad-mobile-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;

    @media only screen and (min-width: ${breakpoints.lg}px) {
      display: none;
    }
  }
`
