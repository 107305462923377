import React from 'react'

import useRegion from '../../hooks/useRegion'
import { header } from '../../styles/components/header/header'
import redesignedHeader from '../../styles/components/header/redesignedHeader'
import Navigation from '../Navigation/Navigation'
import NavigationThemeProvider from '../Navigation/NavigationThemeProvider'

export const Logo = () => {
  const regionName = useRegion()?.name

  return (
    <div className='container-logo-primary'>
      <a href='/'>
        <img src={`/${regionName}.svg`} alt={`News 12 ${regionName}logo`} />
      </a>
      <style jsx>{header}</style>
    </div>
  )
}

const Header = ({ regionName, navigation, banner, newslettersList, marketingNavigation, weatherConditions }) => {
  if (regionName === 'newyork') {
    return (
      <header>
        <Logo />
      </header>
    )
  }

  return (
    <>
      <div className='redesigned-navigation'>
        <NavigationThemeProvider>
          <Navigation
            banner={banner}
            navItems={navigation.header}
            newslettersList={newslettersList}
            marketingNavItems={marketingNavigation}
            weatherConditions={weatherConditions}
          />
        </NavigationThemeProvider>
      </div>
      <style jsx>{redesignedHeader}</style>
    </>
  )
}

export default Header
