import { theme } from '@cheddartv/storybook-news'

import styled from 'styled-components'

export const StyledAdFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0.5rem;
  background-color: white;
  box-sizing: border-box;
  z-index: ${theme.zIndex.sticky};
`

export const StyledAdCustomContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
  background-color: transparent;
  z-index: ${theme.zIndex.negative};
`

export const StyledTaboolaContainer = styled.div`
  width: 100%;
`
