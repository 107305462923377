'use client'

import { Container, FlexContainer, Footer, LineSeparator, LineSeparatorVariant } from '@cheddartv/storybook-news'

import { useMemo } from 'react'

import normalizeFooter from '../../data/normalizers/footer/normalizeFooter'
import useBelowBreakpoint from '../../hooks/useBelowBreakpoint'
import useIsProd from '../../hooks/useIsProd'
import { AD_UNIT_SIZES } from '../../utils/adConfig'
import AdUnit from '../Ads/AdUnit'
import { StyledAdFooterContainer } from '../Ads/Ads.styles'
import { useAdsContext } from '../AdsProvider'

export type FooterField = {
  path: string
  isRoot?: boolean
  name: string
}

export type FooterSectionProps = {
  footer: {
    contacts: FooterField[]
    affliates: FooterField[]
    about: FooterField[]
  }
}

const FooterSection = ({ footer }: FooterSectionProps) => {
  const isMobile = useBelowBreakpoint('sm')
  const isTablet = useBelowBreakpoint('lg')

  const { categoriesNames, adsEnabled, sponsorshipAdsEnabled, dvdPreRollEnabled } = useAdsContext()

  // We show the custom ad unit only on the production due to the Gumgum ad behavior on the stage
  // Gumgum ads appear and overlay the content, which makes the stage environment testing tricky
  const isProd = useIsProd()
  const isCustomAdAvailable = adsEnabled && isProd && dvdPreRollEnabled

  const { bottomOptions, topLists } = useMemo(() => normalizeFooter(footer), [footer])

  const desktopAdUnitSizes = useMemo(
    () => ({
      maxWidth: '728px'
    }),
    []
  )

  return (
    <>
      <footer className='main-footer redesigned-footer'>
        <Container>
          <LineSeparator variant={LineSeparatorVariant.Strong} />
          <Footer
            topLists={topLists}
            bottomOptions={bottomOptions}
            homeUrl='/'
            externalLinks={{
              appStoreUrl: 'https://apps.apple.com/us/app/news-12-mobile/id444268184',
              googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.sentinelbd.news12&hl=en_US'
            }}
          />
        </Container>
        {sponsorshipAdsEnabled && !isTablet && (
          <StyledAdFooterContainer>
            <FlexContainer justifyContent='center'>
              <AdUnit
                adUnitTemplate='leaderboard'
                adUnitSizes={desktopAdUnitSizes}
                contentfulCategories={categoriesNames}
                id='ad-desktop-footer'
                type='footerDesktopSponsorship'
              />
            </FlexContainer>
          </StyledAdFooterContainer>
        )}
        {adsEnabled && isMobile && (
          <StyledAdFooterContainer>
            <FlexContainer justifyContent='center'>
              <AdUnit
                adUnitTemplate='leaderboard'
                adUnitSizes={AD_UNIT_SIZES}
                contentfulCategories={categoriesNames}
                id='ad-mobile-footer'
                type='footerRedesign'
              />
            </FlexContainer>
          </StyledAdFooterContainer>
        )}
      </footer>
      {isCustomAdAvailable && <AdUnit contentfulCategories={categoriesNames} id='ad-custom' type='custom' />}
    </>
  )
}

export default FooterSection
