import { createContentfulSchema } from '../../utils/validators/validators'

import { number, object, string } from 'yup'

export const mediaSchema = object({
  title: string(),
  description: string(),
  file: object({
    url: string(),
    details: object({
      size: number(),
      image: object({
        width: number(),
        height: number()
      })
    }),
    fileName: string(),
    contentType: string()
  })
})

export const mediaContentfulSchema = createContentfulSchema(mediaSchema)
