import { ResetCSS } from '@cheddartv/storybook-news'

import React from 'react'

import graphikFont from '../../styles/global/fonts'

import { createGlobalStyle } from 'styled-components'

const CustomGlobalCSS = createGlobalStyle`
    html {
        font-family: ${graphikFont.style.fontFamily};
    }
`

const GlobalCSS = () => (
  <>
    <ResetCSS />
    <CustomGlobalCSS />
  </>
)

export default GlobalCSS
