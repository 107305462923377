import { ContentfulLocale } from '../../types'
import { TypeStory } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'
import { bodyTextSchema, richTextSchema } from './commonSchemas'

import { array, boolean, date, InferType, mixed, object, string } from 'yup'

export const storySchema = object({
  slug: string().required(),
  title: string().required(),
  description: string(),
  authors: array(),
  body: bodyTextSchema.notRequired(),
  content: richTextSchema.notRequired(),
  withAds: boolean(),
  withTaboola: boolean(),
  withPreRoll: boolean(),
  withDvdPreRoll: boolean(),
  categories: mixed(),
  keywords: mixed(),
  originalPublishedAt: date(),
  publishedAt: date(),
  thumbnailAttribution: string().nullable(),
  videoFile: mixed(),
  heroImage: mixed(),
  thumbnail: mixed().required()
})

type TStorySchema = InferType<typeof storySchema>

export const storyContentfulSchema = createContentfulSchema<TStorySchema>(storySchema)

export const validateStory =
  createSchemaValidator<TypeStory<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(storyContentfulSchema)
