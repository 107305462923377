export const PROD_DOMAIN = 'news12.com'

export const isProd = () => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location

    return hostname.includes(PROD_DOMAIN)
  }

  return false
}
