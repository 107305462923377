export enum ModalName {
  NEWSLETTER_MODAL = 'NEWSLETTER_MODAL',
  STATUS_MESSAGE_MODAL = 'STATUS_MESSAGE_MODAL'
}

export type ModalEntry = {
  id: string
  name: ModalName
  props: Record<string, any>
}
