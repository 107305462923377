import fetchContentful from '../../core/fetchContentful'
import { ContentfulLocale } from '../../types'
import { TypeNavigation } from '../../types/contentful'
import marketingNavigationFieldsSelector from '../selectors/marketingNavigationFieldsSelector'
import { extractMarketingNavigation } from '../transformers/extractNavigation'

const prepareMarketingNavigationParams = (marketingNavId: string) => ({
  'sys.id': marketingNavId,
  content_type: 'navigation',
  include: '2',
  select: marketingNavigationFieldsSelector
})

const fetchMarketingNavigation = async (marketingNavId: string) => {
  const navigationEntries = await fetchContentful<TypeNavigation<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]>(
    prepareMarketingNavigationParams(marketingNavId)
  )

  return extractMarketingNavigation(navigationEntries)
}

export default fetchMarketingNavigation
