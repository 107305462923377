const adConfig = (region, rubiconBundleName, logging) => ({
  amazonPubId: '6fca4ecf-f94b-421e-b485-6c40e0ce22cc',
  unitPath: `/853/${region.adPath}`,
  refreshRate: 30000,
  enableLazyLoad: true,
  lazyLoadConfig: {
    fetchMarginPercent: 0,
    renderMarginPercent: 0,
    mobileScaling: 2.0
  },
  logging,
  universalTargeting: {},
  maxBidMultiplier: 1,
  siteKey: 'news12',
  addSlotLoadTimeout: 100,
  rubiconBundleName,
  openWrapProfileIdDesktop: '5216',
  openWrapProfileIdMobile: '5214'
})

export default function initializeAds(region) {
  if (window.News12Ads) {
    window.News12Ads.initialize(
      adConfig(region, process.env.NEXT_PUBLIC_RUBICON_BUNDLE_NAME, process.env.NEXT_PUBLIC_ADS_LOGGING === 'true')
    )
  } else {
    console.log('error loading ad bundle')
  }
}
