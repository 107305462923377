'use client'

import React, { useEffect, useState } from 'react'
import { observe } from 'react-intersection-observer'

import { TOP_STORIES_SECTION_ID } from '../HomePage/HomePage'
import ThemeProvider, { TTheme } from '../ThemeProvider'

import { usePathname } from 'next/navigation'

const NavigationThemeProvider = ({ children }: { children: any }) => {
  const pathname = usePathname()
  const isHomePage = pathname === '/'
  const [homepageTheme, setHomepageTheme] = useState<TTheme>('dark')

  useEffect(() => {
    if (!isHomePage) {
      return
    }

    const element = document.querySelector(`#${TOP_STORIES_SECTION_ID}`)!

    if (!element) {
      return
    }

    return observe(element, inView => {
      setHomepageTheme(inView ? 'dark' : 'light')
    })
  }, [isHomePage])

  if (!isHomePage) {
    return <>{children}</>
  }

  return <ThemeProvider theme={homepageTheme}>{children}</ThemeProvider>
}

export default NavigationThemeProvider
