import { ContentfulLocale } from '../../types'
import { TypeCollection } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { boolean, InferType, mixed, object, string } from 'yup'

export const collectionSchema = object({
  name: string(),
  title: string(),
  categoryPageHeroImage: mixed(),
  hero: mixed(),
  poster: mixed(),
  promotedPoster: mixed(),
  body: mixed(),
  thumbnail: mixed(),
  slug: string(),
  categories: mixed(),
  requiresVideoFile: boolean()
})

type TCollectionSchema = InferType<typeof collectionSchema>

export const collectionContentfulSchema = createContentfulSchema<TCollectionSchema>(collectionSchema)

export const validateCollection =
  createSchemaValidator<TypeCollection<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(collectionContentfulSchema)
