function handleLog(severity, envelope, callback) {
  const useWinston = !process.browser
  const showLogs = process.env.SHOW_LOGS === 'true'

  if (useWinston && showLogs && severity && envelope) {
    const logger = require('../../utils/winston-logger')

    logger[severity](envelope)

    callback && callback(envelope)
  }
}

const logServerSidePropsError = error => {
  handleLog('error', {
    event: 'Server Side Props Error',
    ...error
  })
}

const logNetworkError = (error, optionalCallback) => {
  handleLog(
    'error',
    {
      event: 'Network Error',
      ...error
    },
    optionalCallback
  )
}

const logRequestMetaData = (data, optionalCallback) => {
  handleLog(
    'info',
    {
      event: 'Request Metadata',
      message: 'Additional Request Context',
      ...data
    },
    optionalCallback
  )
}

export { handleLog, logServerSidePropsError, logNetworkError, logRequestMetaData }
