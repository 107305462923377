import fetchContentful from '../../core/fetchContentful'
import { ContentfulLocale } from '../../types'
import { TypeMenu } from '../../types/contentful'
import extractNavigation, { FooterField, NavigationData } from '../transformers/extractNavigation'

export const paramsForNavigation = (id: string) => ({
  'sys.id': id,
  content_type: 'menu',
  include: '2'
})

const fetchNavigation = async (regionId: string): Promise<NavigationData> => {
  const navigationEntries = await fetchContentful<TypeMenu<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]>(
    paramsForNavigation(regionId)
  )

  let navigationData = extractNavigation(navigationEntries)

  return navigationData
}

export default fetchNavigation
