import { regionAreaCoordinates } from '../../config/regionAreaCoordinates'

import classifyPoint from 'robust-point-in-polygon'

const validateCoordinates = (long: string, lat: string): Record<string, number> | null => {
  const parsedLat = parseFloat(lat)
  const parsedLong = parseFloat(long)

  if (!parsedLat || isNaN(parsedLat) || parsedLat <= -90 || parsedLat >= 90) {
    return null
  }

  if (!parsedLong || isNaN(parsedLong) || parsedLong <= -180 || parsedLong >= 180) {
    return null
  }

  return {
    latitude: parsedLat,
    longitude: parsedLong
  }
}

export const getRegionByGeolocation = ({ lat, long }: Record<string, string>): string | undefined => {
  let clientCoordinates = validateCoordinates(long, lat)

  if (!clientCoordinates?.longitude || !clientCoordinates?.latitude) {
    return
  }

  let locatedRegion: string | undefined

  const { latitude, longitude } = clientCoordinates

  for (let regionCoordinates of regionAreaCoordinates) {
    const { region, areaCoordinates } = regionCoordinates
    const isInside = classifyPoint(areaCoordinates, [latitude, longitude]) <= 0
    if (isInside) {
      locatedRegion = region
      break
    }
  }

  return locatedRegion
}
