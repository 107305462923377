import { sendGAEvent } from '@next/third-parties/google'

export const GTM_ID = 'GTM-NXTKVNC'
export const GA_ID = 'G-CTJFSSEVG4'

export const trackGTMEvent = {
  gaBurgerButtonClick: (isOpen: boolean) => sendGAEvent({ event: isOpen ? 'hamburger_close' : 'hamburger_open' }),
  gaMainMenuLinkClick: link => sendGAEvent({ event: 'hamburger_section', value: { link } }),
  gaSearchClick: (search: FormDataEntryValue) => sendGAEvent({ event: 'search', value: { search } }),
  gaWeatherIconClick: () => sendGAEvent({ event: 'weather_icon' }),
  gaRegionSwitchClick: (regionName: string) => sendGAEvent({ event: 'button_click', value: { regionName } }),
  gaNavbarClick: link => sendGAEvent({ event: 'navbar', value: { link } }),
  gaHamburgerNewsletterClick: () => sendGAEvent({ event: 'hamburger_newsletter' }),
  gaMediaCardClick: ({
    cardTitle,
    sectionTitle,
    cardNumber
  }: {
    cardTitle: string
    sectionTitle: string
    cardNumber: number
  }) => sendGAEvent({ event: 'card_clicked', cardTitle, sectionTitle, cardNumber }),
  gaHeroClick: ({ title, href }: { title: string; href: string }) =>
    sendGAEvent({ event: 'top_story_hero_click', title, href }),
  gaCarouselScroll: ({ category }: { category: string }) => sendGAEvent({ event: 'carousel_scroll', category }),
  gaBreakingNewsBannerClick: () => sendGAEvent({ event: 'breaking_news_banner_click' }),
  gaMoreStoriesMediaCardClick: (position: number, name: string) =>
    sendGAEvent({ event: 'more_stories_media_card_click', item_position: position, item_name: name }),
  gaRegionSwitchTooltipShown: () => sendGAEvent({ event: 'region_switcher_tooltip' }),
  gaStoryPageVisit: ({ categories, authors }) =>
    sendGAEvent({ event: 'On_Story_Page', storyCategory: categories, storyAuthor: authors })
}
