'use client'

import { Button, Container, Hero, PlayIcon, FlexContainer } from '@cheddartv/storybook-news'

import React, { useEffect, useMemo } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import useBelowBreakpoint from '../../hooks/useBelowBreakpoint'
import { TopStory } from '../../types'
import { AD_UNIT_SIZES } from '../../utils/adConfig'
import { getDuration } from '../../utils/duration'
import AdUnit from '../Ads/AdUnit'
import { useAdsContext } from '../AdsProvider'
import TopStories from '../TopStories/TopStories'

import { useTheme } from 'styled-components'

type TopStoriesSectionProps = {
  showAdSponsorship: boolean
  topStories: TopStory[]
}

const TopStoriesSection = ({ showAdSponsorship, topStories }: TopStoriesSectionProps) => {
  const theme = useTheme()

  const isTablet = useBelowBreakpoint('lg')

  const { changeSponsorshipAdsEnabled } = useAdsContext()

  const [hero, ...stories] = useMemo(
    () =>
      topStories.map(story => {
        return {
          id: story.id,
          heading: story.title,
          thumbnail: story.thumbnail,
          heroImage: story.heroImage,
          href: `/${story.slug}`,
          durationTime: story.videoFile?.duration ? getDuration(story.videoFile?.duration) : null,
          hasWatchButton: Boolean(story.videoFile)
        }
      }),
    [topStories]
  )

  useEffect(() => {
    changeSponsorshipAdsEnabled(showAdSponsorship)
  }, [showAdSponsorship])

  const desktopAdUnitSizes = useMemo(
    () => ({
      maxWidth: { default: '100%' },
      height: { default: 'auto', sm: 'auto' },
      minHeight: '90px'
    }),
    []
  )

  return (
    <>
      <Container fullWidth padding='1rem' backgroundColor={theme.colors.mutedBackgroundColor}>
        <FlexContainer justifyContent='center'>
          {showAdSponsorship && !isTablet ? (
            <AdUnit
              adUnitTemplate='leaderboard'
              adUnitSizes={desktopAdUnitSizes}
              type='leaderboardHomePageSponsorship'
              id='ad-banner-top'
            />
          ) : (
            <AdUnit
              adUnitTemplate='leaderboard'
              adUnitSizes={AD_UNIT_SIZES}
              type='leaderboardRedesign'
              id='ad-banner-top'
            />
          )}
        </FlexContainer>
      </Container>
      <Container fullWidth backgroundColor={theme.colors.slate}>
        <Hero
          title={hero.heading}
          thumbnail={{ src: hero.thumbnail || hero.heroImage, alt: hero.heading }}
          href={hero.href}
          onClick={trackGTMEvent.gaHeroClick}
          action={
            hero.hasWatchButton && (
              <Button size='regular' ariaLabel='Play the videomaterial'>
                <PlayIcon color={theme.colors.white} />
                Watch
              </Button>
            )
          }
        />
        <Container
          padding={{ default: '0 0 1px', sm: '0 1rem 2.5rem', md: '0 3.75rem 2.5rem' }}
          margin={{ default: '0 auto', sm: '2.5rem auto 0' }}>
          <TopStories stories={stories} />
        </Container>
      </Container>
    </>
  )
}

export default TopStoriesSection
