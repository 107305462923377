import resolveImageDomain from './resolveImageDomain'

const FALLBACK_IMAGE_URL =
  '//images-cf.news12static.com/3kqcuzntcg31/3npmffTlb8qXpqzrYLWXTq/c59543c81cc66265ca2bf4ebb4c7edb8/News12_1920x1080_v2.jpg'

export type ResolveImageParams = {
  url?: string
  width?: number
  height?: number
  quality?: number
}

const resolveImage = ({ url, width = 1920, height = 1080, quality = 80 }) =>
  `https:${resolveImageDomain(url) || FALLBACK_IMAGE_URL}?fit=thumb&w=${width}&h=${height}&q=${quality}&fm=webp`

export default resolveImage
