import { ContentfulLocale } from '../../types'
import { TypeRegion } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { boolean, InferType, mixed, number, object, string } from 'yup'

export const regionSchema = object({
  name: string().required(),
  subdomain: string().required(),
  location: object({
    lon: number(),
    lat: number()
  }),
  zipcode: string().required(),
  weather: mixed(),
  authwall: boolean().required()
})

type TRegionSchema = InferType<typeof regionSchema>

export const regionContentfulSchema = createContentfulSchema<TRegionSchema>(regionSchema)

export const validateRegion =
  createSchemaValidator<TypeRegion<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(regionContentfulSchema)
