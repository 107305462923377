import { ContentfulLocale } from '../../types'
import { TypeProjects } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'
import { richTextSchema } from './commonSchemas'

import { InferType, mixed, object, string } from 'yup'

export const projectsSchema = object({
  title: string().required(),
  slug: string(),
  thumbnail: mixed(),
  poster: mixed(),
  description: string(),
  hero: mixed(),
  body: richTextSchema.notRequired(),
  credits: richTextSchema.notRequired(),
  css: string()
})

type TProjectsSchema = InferType<typeof projectsSchema>

export const projectsContentfulSchema = createContentfulSchema<TProjectsSchema>(projectsSchema)

export const validateProjects =
  createSchemaValidator<TypeProjects<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(projectsContentfulSchema)
