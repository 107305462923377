import { ContentfulLocale } from '../../types'
import { TypeNewsletter } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { array, boolean, InferType, mixed, object, string } from 'yup'

const letterListItem = object({
  name: string().required(),
  title: string().required(),
  checked: boolean().required(),
  description: string().required()
})

export const newsletterSchema = object({
  lettersList: array().of(letterListItem).required()
})

type TNewsletterSchema = InferType<typeof newsletterSchema>

export const newsletterContentfulSchema = createContentfulSchema<TNewsletterSchema>(newsletterSchema)

export const validateNewsletter =
  createSchemaValidator<TypeNewsletter<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(newsletterContentfulSchema)
