'use client'

import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

type CategoriesNames = string[]

type AdsContextValues = {
  adsEnabled: boolean
  sponsorshipAdsEnabled: boolean
  categoriesNames: CategoriesNames
  dvdPreRollEnabled: boolean
  changeAdsEnabled: (payload: boolean) => void
  changeSponsorshipAdsEnabled: (payload: boolean) => void
  changeCategoriesNames: (payload: CategoriesNames) => void
  changeDvdPreRollEnabled: (payload: boolean) => void
  resetAdsEnabled: () => void
  resetCategoriesNames: () => void
  resetDvdPreRollEnabled: () => void
}

export const AdsContext = createContext<AdsContextValues>({
  adsEnabled: true,
  categoriesNames: [],
  dvdPreRollEnabled: true,
  sponsorshipAdsEnabled: false,
  changeAdsEnabled: () => {},
  changeCategoriesNames: () => {},
  changeDvdPreRollEnabled: () => {},
  changeSponsorshipAdsEnabled: () => {},
  resetAdsEnabled: () => {},
  resetCategoriesNames: () => {},
  resetDvdPreRollEnabled: () => {}
})

export const useAdsContext = () => useContext(AdsContext)

const AdsProvider = ({ children }: { children: ReactNode }) => {
  const [adsEnabled, setAdsEnabled] = useState(true)
  const [dvdPreRollEnabled, setDvdPreRollEnabled] = useState(true)
  const [sponsorshipAdsEnabled, setSponsorshipAdsEnabled] = useState(false)
  const [categoriesNames, setCategoriesNames] = useState<CategoriesNames>([])

  const resetAdsEnabled = useCallback(() => setAdsEnabled(true), [])
  const resetCategoriesNames = useCallback(() => setCategoriesNames([]), [])
  const resetDvdPreRollEnabled = useCallback(() => setDvdPreRollEnabled(true), [])

  return (
    <AdsContext.Provider
      value={{
        changeAdsEnabled: setAdsEnabled,
        changeCategoriesNames: setCategoriesNames,
        changeDvdPreRollEnabled: setDvdPreRollEnabled,
        changeSponsorshipAdsEnabled: setSponsorshipAdsEnabled,
        adsEnabled,
        categoriesNames,
        dvdPreRollEnabled,
        sponsorshipAdsEnabled,
        resetAdsEnabled,
        resetCategoriesNames,
        resetDvdPreRollEnabled
      }}>
      {children}
    </AdsContext.Provider>
  )
}

export default AdsProvider
