import regions from '../../config/regions'
import fetchContentful from '../../core/fetchContentful'
import { ContentfulLocale } from '../../types'
import { TypeNewsletter } from '../../types/contentful'
import newsletterListFieldsSelector from '../selectors/newsletterListFieldsSelector'
import { validateNewsletter } from '../validators'

export const paramsForNewsletter = (regionId: string) => ({
  content_type: 'newsletter',
  'fields.region.sys.id[in]': regionId,
  include: '1',
  select: newsletterListFieldsSelector
})

export type NewslettersListItem = {
  name: string
  title: string
  checked: boolean
  description: string
}

export type NewslettersList = NewslettersListItem[]

const fetchNewslettersList = async (regionName: string): Promise<NewslettersList> => {
  const regionId = regions[regionName]?.contentfulId || regions.default.contentfulId
  const newsletterEntries = await fetchContentful<TypeNewsletter<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>[]>(
    paramsForNewsletter(regionId)
  )
  const newsletter = newsletterEntries[0]

  return validateNewsletter(newsletter) ? (newsletter.fields.lettersList as NewslettersList) : []
}

export default fetchNewslettersList
