import { ContentfulLocale } from '../../types'
import { TypeHomepage } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { boolean, InferType, mixed, number, object, string } from 'yup'

export const homepageSchema = object({
  topStories: mixed().required(),
  collections: mixed().required(),
  title: string(),
  description: string(),
  thumbnail: mixed(),
  calendar: number(),
  promotedContentTitle: string(),
  promotedContent: mixed(),
  region: string(),
  whereToWatch: mixed(),
  talent: mixed(),
  homepageSponsorship: boolean(),
  withDvdPreRoll: boolean()
})

type THomepageSchema = InferType<typeof homepageSchema>

export const homepageContentfulSchema = createContentfulSchema<THomepageSchema>(homepageSchema)

export const validateHomepage =
  createSchemaValidator<TypeHomepage<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(homepageContentfulSchema)
