import fetchQueryWrapper from '../../core/fetchQueryWrapper'
import footerNYFieldsSelector from '../selectors/footerNYFieldsSelector'
import { extractFooter, NavigationFooter } from '../transformers/extractNavigation'

import resolveResponse from 'contentful-resolve-response'

const fetchFooterNY = async (): Promise<NavigationFooter> => {
  const NYlandingData = await fetchQueryWrapper(
    {
      content_type: 'news12NewYork',
      include: 1,
      select: footerNYFieldsSelector
    },
    resolveResponse
  )

  const footer = NYlandingData?.[0]?.fields?.footer

  return extractFooter(footer)
}

export default fetchFooterNY
