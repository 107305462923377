export type LinksObjectItem = {
  path: string
}

export const isValidLinksObjectItem = (item: unknown): item is LinksObjectItem =>
  typeof item === 'object' &&
  item !== null &&
  'path' in item &&
  typeof item.path === 'string' &&
  item.path.trim() !== ''
