export * from './authorValidator'
export * from './categoryValidator'
export * from './keywordValidator'
export * from './mediaValidator'
export * from './storyValidator'
export * from './collectionValidator'
export * from './newsletterValidator'
export * from './projectsValidator'
export * from './platformsListValidator'
export * from './platformValidator'
export * from './regionValidator'
export * from './talentValidator'
export * from './anchorValidator'
export * from './storyListValidator'
export * from './homepageValidator'
