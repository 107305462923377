import theme from '../../theme/news12'

import css from 'styled-jsx/css'

export default css.div`
  :global(#__next) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .wrapper {
    display: flex;
    align-self: center;
    flex-grow: 1;
    flex-direction: column;
    max-width: 600px;
    margin: 90px auto;
    max-width: 600px;
    padding: 0 24px;
    box-sizing: border-box;
  }

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    font-style: normal;
    border: none;
    color: ${theme.amp.meta.themeColor};
    padding: 0;
    margin: 0;
    text-transform: none;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: ${theme.amp.meta.themeColor};
    margin: 1.125rem 0;
    padding: 0;
  }

  a {
    color: ${theme.colors.royalBlue};
    text-decoration: none;
  }

  img {
    width: 100%;
    max-width: 130px;
    height: auto;
    margin: 0 0 50px 0;
  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 18px;
    }
  }
`
