import { useEffect, useState } from 'react'

import { isProd } from '../utils/common'

const useIsProd = () => {
  const [isEnvProd, setIsEnvProd] = useState(false)

  useEffect(() => {
    setIsEnvProd(isProd())
  }, [])

  return isEnvProd
}

export default useIsProd
