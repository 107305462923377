import body from '../components/Amp/Body'
import { STATUS_CODE } from '../data/enums/statusCodes'

import resolveResponse from 'contentful-resolve-response'

type FetchContentfulParams = Record<string, string>

type FetchContentfulOptions = {
  env?: string
  isPreview?: boolean
  resolveLinkedContent?: boolean
}

const prepareParams = (params: FetchContentfulParams) => new URLSearchParams(params)

/**
 * @deprecated Use fetchContentfulWithStatusCode instead.
 */
const fetchContentful = async <T>(
  params: FetchContentfulParams,
  { env, isPreview, resolveLinkedContent = true }: FetchContentfulOptions = {}
): Promise<T> => {
  const envPath = env ? `environments/${env}/` : ''
  const subDomain = isPreview ? 'preview' : 'cdn'
  const spaceId = process.env.CONTENTFUL_SPACE_ID as string
  const token = (isPreview ? process.env.CONTENTFUL_PREVIEW_API : process.env.CONTENTFUL_CONTENT_DELIVERY_API) as string

  if (!spaceId || !token) {
    throw new Error('The space ID and token must be provided.')
  }

  const url = new URL(`https://${subDomain}.contentful.com/spaces/${spaceId}/${envPath}entries`)
  const searchParams = prepareParams(params)
  url.search = searchParams.toString()

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  }

  try {
    const response = await fetch(url, { headers, cache: 'no-store' })
    const parsedResponse = await response.json()

    return resolveLinkedContent ? resolveResponse(parsedResponse) : parsedResponse
  } catch (error) {
    console.error('Fetching data failed:', error)
    throw error
  }
}

export default fetchContentful

export const fetchContentfulWithStatusCode = async <T>(
  params: FetchContentfulParams,
  options: FetchContentfulOptions = {}
): Promise<{ statusCode: STATUS_CODE; entries: T | null }> => {
  const { isPreview, resolveLinkedContent = true } = options
  const envPath = process.env.CONTENTFUL_ENVIRONMENT_ID ? `environments/${process.env.CONTENTFUL_ENVIRONMENT_ID}/` : ''
  const subDomain = isPreview ? 'preview' : 'cdn'
  const spaceId = process.env.CONTENTFUL_SPACE_ID as string
  const token = (isPreview ? process.env.CONTENTFUL_PREVIEW_API : process.env.CONTENTFUL_CONTENT_DELIVERY_API) as string

  if (!spaceId || !token) {
    throw new Error('The space ID and token must be provided.')
  }

  const url = new URL(`https://${subDomain}.contentful.com/spaces/${spaceId}/${envPath}entries`)
  const searchParams = prepareParams(params)
  url.search = searchParams.toString()

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  }

  try {
    const response = await fetch(url, { headers, cache: 'no-store' })
    const parsedResponse = await response.json()
    const body = resolveLinkedContent ? resolveResponse(parsedResponse) : parsedResponse
    const statusCode = mapContentfulStatusCode(response.status, body)

    return { statusCode, entries: body as T }
  } catch (error) {
    console.error('Fetching Contentful data failed:', error)
    return { statusCode: STATUS_CODE.internalServerError, entries: null }
  }
}

function mapContentfulStatusCode(statusCode: number, body: any): number {
  //https://altice.atlassian.net/wiki/spaces/APPS/pages/60262776835/Crunch+HTTP+Error+Codes
  switch (statusCode) {
    case STATUS_CODE.success:
      if (isBodyEmpty(body)) {
        //Contentful will respond with a 200 status code and an empty body if the slug is not found
        return STATUS_CODE.notFound
      }
      return STATUS_CODE.success
    case STATUS_CODE.notFound:
      return STATUS_CODE.notFound
    default:
      //all other Contentful errors are processed as 500s
      return STATUS_CODE.internalServerError
  }
}

function isBodyEmpty(body: any) {
  if (!body) {
    return true
  }

  if (Array.isArray(body)) {
    return body.length === 0
  }

  if (typeof body === 'object') {
    return Object.keys(body).length === 0
  }

  return false
}
