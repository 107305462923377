import { ContentfulLocale } from '../../types'
import { TypeList } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, mixed, object, string } from 'yup'

export const storyListSchema = object({
  name: string(),
  title: string(),
  stories: mixed().required(),
  region: mixed()
})

type TStoryListSchema = InferType<typeof storyListSchema>

export const storyListContentfulSchema = createContentfulSchema<TStoryListSchema>(storyListSchema)

export const validateStoryList =
  createSchemaValidator<TypeList<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(storyListContentfulSchema)
