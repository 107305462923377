import { ContentfulLocale } from '../../types'
import { TypeKeyword } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, object, string } from 'yup'

export const keywordSchema = object({
  name: string().required()
})

type TKeywordSchema = InferType<typeof keywordSchema>

export const keywordContentfulSchema = createContentfulSchema<TKeywordSchema>(keywordSchema)

export const validateKeyword =
  createSchemaValidator<TypeKeyword<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(keywordContentfulSchema)
