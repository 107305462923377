'use client'

import { useEffect } from 'react'

import { useAdsContext } from './AdsProvider'

type DvdPreRollAdManagerProps = {
  isEnabled: boolean
}

const DvdPreRollAdManager = ({ isEnabled }: DvdPreRollAdManagerProps) => {
  const { changeDvdPreRollEnabled, resetDvdPreRollEnabled } = useAdsContext()

  useEffect(() => {
    changeDvdPreRollEnabled(isEnabled)

    return () => resetDvdPreRollEnabled()
  }, [isEnabled])

  return null
}

export default DvdPreRollAdManager
