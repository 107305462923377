'use client'

import { darkTheme, lightTheme, ThemeProvider as News12ThemeProvider } from '@cheddartv/storybook-news'

import { ReactNode } from 'react'

export type TTheme = 'dark' | 'light'

const ThemeProvider = ({ children, theme = 'light' }: { children: ReactNode; theme?: TTheme }) => {
  return <News12ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>{children}</News12ThemeProvider>
}

export default ThemeProvider
