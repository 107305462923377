'use client'

import {
  BreakingNewsBanner as BreakingNews,
  BreakingNewsBannerProps as StoryBookBreakingNewsBannerProps
} from '@cheddartv/storybook-news'

import React, { useCallback } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import ThemeProvider from '../ThemeProvider'

import { usePathname } from 'next/navigation'

type BreakingNewsBannerProps = StoryBookBreakingNewsBannerProps

const BreakingNewsBanner = ({ title, description, type = 'News', href, onClose }: BreakingNewsBannerProps) => {
  const isHomePage = usePathname() === '/'

  const handleBannerClick = useCallback(() => {
    trackGTMEvent.gaBreakingNewsBannerClick()
  }, [])

  return (
    <ThemeProvider theme={isHomePage ? 'dark' : 'light'}>
      <BreakingNews
        title={title}
        description={description}
        type={type}
        href={href}
        onClose={onClose}
        onClick={handleBannerClick}
      />
    </ThemeProvider>
  )
}

export default BreakingNewsBanner
