import localFont from 'next/font/local'

const graphik = localFont({
  src: [
    {
      path: '../../public/fonts/graphik/Graphik-Thin.otf',
      style: 'normal',
      weight: '100'
    },
    {
      path: '../../public/fonts/graphik/Graphik-ThinItalic.otf',
      style: 'italic',
      weight: '100'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Extralight.otf',
      style: 'normal',
      weight: '200'
    },
    {
      path: '../../public/fonts/graphik/Graphik-ExtralightItalic.otf',
      style: 'italic',
      weight: '200'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Light.otf',
      style: 'normal',
      weight: '300'
    },
    {
      path: '../../public/fonts/graphik/Graphik-LightItalic.otf',
      style: 'italic',
      weight: '100'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Regular.otf',
      style: 'normal',
      weight: '400'
    },
    {
      path: '../../public/fonts/graphik/Graphik-RegularItalic.otf',
      style: 'italic',
      weight: '400'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Medium.otf',
      style: 'normal',
      weight: '500'
    },
    {
      path: '../../public/fonts/graphik/Graphik-MediumItalic.otf',
      style: 'italic',
      weight: '500'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Semibold.otf',
      style: 'normal',
      weight: '600'
    },
    {
      path: '../../public/fonts/graphik/Graphik-SemiboldItalic.otf',
      style: 'italic',
      weight: '600'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Regular.otf',
      style: 'normal',
      weight: '400'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Regular.otf',
      style: 'normal',
      weight: '400'
    },
    {
      path: '../../public/fonts/graphik/Graphik-Regular.otf',
      style: 'normal',
      weight: '400'
    }
  ]
})

export default graphik
