const winston = require('winston')
const { createLogger, format, transports } = winston
const { timestamp, errors, combine, json } = format

const options = {
  console: {
    level: process.env.LOG_LEVEL || 'info',
    json: true,
    handleExceptions: true,
    handleRejections: true,
    humanReadableUnhandledException: true
  }
}

const combinedFormat = combine(errors({ stack: true }), timestamp(), json())

const logger = createLogger({
  level: process.env.LOG_LEVEL || 'info',
  format: combinedFormat,
  defaultMeta: { app: 'crunch' },
  transports: [new transports.Console(options.console)],
  exitOnError: false
})

logger.info('winston-logger was successfully invoked!')

module.exports = logger
