import { useEffect } from 'react'

import initializeFaro from '../../config/faroConfig'

const FaroMonitoring = ({ children }) => {
  useEffect(() => {
    initializeFaro()
  }, [])

  return children
}

export default FaroMonitoring
