import breakpoints from '../../../theme/breakpoints'

import { global } from 'styled-jsx/css'

export default global`
  .redesigned-navigation {
    position: sticky;
    z-index: 99999;
    top: 0;
  
    h1, h2, h3, h4, h5, h6 {
      border-bottom: none;
      padding: 0;
    }

    div:nth-child(3) > div > div > div:nth-child(2) > div > div > h3 {
      margin-top: 20px;
    }

    div > div > h3  {
      width: unset;
    }

    section:nth-child(5) > h2 {
      text-align: start;
    }

    div:nth-child(4) > div > section > section:nth-child(4) {
      flex: 1;
      align-items: flex-start;
    }

    header {
      z-index: 99999;
    }

    button {
      font-family: Graphik, sans-serif;
    }
  }  
`
