import { ContentfulLocale } from '../../types'
import { TypeCategory } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, object, string } from 'yup'

export const categorySchema = object({
  name: string().required()
})

type TCategorySchema = InferType<typeof categorySchema>

export const categoryContentfulSchema = createContentfulSchema<TCategorySchema>(categorySchema)

export const validateCategory =
  createSchemaValidator<TypeCategory<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(categoryContentfulSchema)
