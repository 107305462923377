export default {
  amp: {
    images: {
      logo: '/brooklyn.png',
      altLogo: '/brooklyn.png',
      favicon: '/favicon.ico',
      close: '/close_icon.svg',
      hamburger: '/hamburger.svg',
      social: {
        twitter: '/social/twitter.svg',
        facebook: '/social/facebook.svg',
        instagram: '/social/instagram.svg'
      }
    },
    meta: {
      themeColor: '#000000'
    }
  },
  breakpoints: {
    sm: 540,
    md: 768,
    lg: 1024,
    xl: 1440
  },
  colors: {
    alabaster: '#fcfcfc',
    alertRed: '#d1193e',
    alertYellow: '#ffda1b',
    black: '#010101',
    blackDelicata: '#0b0b0b',
    blueHover: '#2057dd',
    blueHover2: '#2553C5',
    binaryGray: '#101010',
    brightYellow: '#f2c400',
    brilliance: '#fdfdfd',
    charcoal: '#464646',
    citrine: '#f0f200',
    codGray: '#121212',
    codGrayLighter: '#131313',
    drWhite: '#fafafa',
    graniteGray: '#666666',
    lightGray: '#e6e6e6',
    lightGray2: '#bcbcbc',
    lightGray3: '#EEEEEE',
    lightGray4: '#656565',
    lightGray5: '#979797',
    lightBlue: '#3970F6',
    mineShaft: '#2c2c2c',
    mourineGray: '#7c7c7c',
    mouseGray: '#505050',
    navy: '#133075',
    newCar: '#2056dc',
    olympus: '#787679',
    royalBlue: '#417af6',
    sapphire: '#123075',
    turbo: '#e3e500',
    vapor: '#e7e7e7',
    veryLightGray: '#efefef',
    white: '#ffffff',
    zambezi: '#5a5a5a',
    social: {
      twitter: '#00acee',
      facebook: '#3b5998',
      linkedin: '#0e76a8',
      instagram: '#d62976'
    }
  }
}
