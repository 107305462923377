import type { TypeCollectionSkeleton } from './TypeCollection'
import type { TypeListSkeleton } from './TypeList'
import type { TypePlatformsListSkeleton } from './TypePlatformsList'
import type { TypeProjectsSkeleton } from './TypeProjects'

import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeNews12NewYorkFields {
  pageTitle: EntryFieldTypes.Symbol
  banner: EntryFieldTypes.AssetLink
  bannerTitle?: EntryFieldTypes.RichText
  description?: EntryFieldTypes.Text
  thumbnail?: EntryFieldTypes.AssetLink
  topStories: EntryFieldTypes.EntryLink<TypeListSkeleton>
  promotedContentTitle?: EntryFieldTypes.Symbol
  promotedContent?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeCollectionSkeleton | TypeProjectsSkeleton>>
  socialLinks?: EntryFieldTypes.Object
  content?: EntryFieldTypes.RichText
  platformsList?: EntryFieldTypes.EntryLink<TypePlatformsListSkeleton>
  platformsListN12?: EntryFieldTypes.EntryLink<TypePlatformsListSkeleton>
  footer?: EntryFieldTypes.Object
}

export type TypeNews12NewYorkSkeleton = EntrySkeletonType<TypeNews12NewYorkFields, 'news12NewYork'>
export type TypeNews12NewYork<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeNews12NewYorkSkeleton,
  Modifiers,
  Locales
>

export function isTypeNews12NewYork<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeNews12NewYork<Modifiers, Locales> {
  return entry.sys.contentType?.sys.id === 'news12NewYork'
}
