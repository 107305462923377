'use client'

import React from 'react'

import style from '../../styles/components/notfound'

function Error() {
  return (
    <div className='wrapper'>
      <div>
        <img src='/default.svg' alt='news 12 logo' />
      </div>
      <h1>Internal Server Error</h1>
      <p>
        Something went wrong, our engineers are working on the issue. Try going back to the previous page, or click{' '}
        <a href='http://news12.com'>here</a> to go to the homepage.
      </p>
      <style jsx>{style}</style>
    </div>
  )
}

export default Error
