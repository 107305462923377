import { NewsletterModal, StatusMessageModal } from '@cheddartv/storybook-news'

import { ModalName } from './types'

export const modalComponents = {
  [ModalName.NEWSLETTER_MODAL]: NewsletterModal,
  [ModalName.STATUS_MESSAGE_MODAL]: StatusMessageModal
}

export default modalComponents
