import { ContentfulLocale } from '../../types'
import { TypeTalent } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, mixed, object, string } from 'yup'

export const talentSchema = object({
  pageTitle: string(),
  title: string(),
  thumbnail: mixed(),
  description: string(),
  region: mixed(),
  anchors: mixed().required(),
  regionName: string()
})

type TTalentSchema = InferType<typeof talentSchema>

export const talentContentfulSchema = createContentfulSchema<TTalentSchema>(talentSchema)

export const validateTalent =
  createSchemaValidator<TypeTalent<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(talentContentfulSchema)
