import {
  ConsoleInstrumentation, Faro,
  getWebInstrumentations,
  initializeFaro as initialize
} from '@grafana/faro-react'

const initializeFaro: () => Faro = (): Faro => {

  return initialize({
    url: process.env.NEXT_PUBLIC_GRAFANA_FARO_COLLECTOR_URL,
    app: {
      name: 'crunch',
      version: '1.0.0',
      environment: process.env.NODE_ENV
    },
    sessionTracking: {
      samplingRate: 0.01
    },
    instrumentations: [...getWebInstrumentations(), new ConsoleInstrumentation()]
  })
}

export default initializeFaro
