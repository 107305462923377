import { ContentfulLocale } from '../../types'
import { TypePlatformsList } from '../../types/contentful'
import { createContentfulSchema, createSchemaValidator } from '../../utils/validators/validators'

import { InferType, mixed, object, string } from 'yup'

export const platformsListSchema = object({
  name: string().required(),
  title: string().required(),
  platforms: mixed()
})

type TPlatformsListSchema = InferType<typeof platformsListSchema>

export const platformsListContentfulSchema = createContentfulSchema<TPlatformsListSchema>(platformsListSchema)

export const validatePlatformsList =
  createSchemaValidator<TypePlatformsList<'WITHOUT_UNRESOLVABLE_LINKS', ContentfulLocale>>(
    platformsListContentfulSchema
  )
