type Coordinate = [number, number]

type RegionArea = {
  region: string
  areaCoordinates: Coordinate[]
}

export const regionAreaCoordinates: RegionArea[] = [
  {
    region: 'bronx',
    areaCoordinates: [
      [40.9015389, -73.8612722],
      [40.9158096, -73.9127706],
      [40.8781802, -73.9268468],
      [40.8727286, -73.909509],
      [40.8664977, -73.911054],
      [40.8565011, -73.9210103],
      [40.8352045, -73.9340566],
      [40.8084143, -73.9335656],
      [40.8010078, -73.9279007],
      [40.7955499, -73.9114212],
      [40.8032169, -73.7857651],
      [40.8733477, -73.7534928],
      [40.8959295, -73.8382935],
      [40.9046228, -73.8396668],
      [40.9086447, -73.8551163],
      [40.9015389, -73.8612722]
    ]
  },
  {
    region: 'brooklyn',
    areaCoordinates: [
      [40.7057203, -73.9995841],
      [40.6867184, -74.0126304],
      [40.6598984, -74.0514259],
      [40.5559013, -74.0380363],
      [40.5796342, -73.8670615],
      [40.6145662, -73.8320426],
      [40.6572939, -73.8615684],
      [40.6631538, -73.8564185],
      [40.6961713, -73.8698694],
      [40.6841965, -73.894932],
      [40.7198544, -73.924801],
      [40.7292212, -73.9313242],
      [40.7398873, -73.9529535],
      [40.735465, -73.969433],
      [40.708144, -73.9745828],
      [40.7057203, -73.9995841]
    ]
  },
  {
    region: 'connecticut',
    areaCoordinates: [
      [41.4295993, -71.8031055],
      [42.0220078, -71.7976124],
      [42.0403688, -72.7561695],
      [42.0077234, -72.7616627],
      [41.995477, -72.8193409],
      [42.032209, -72.8165943],
      [42.050567, -73.4840137],
      [41.2935431, -73.5554249],
      [41.2192107, -73.4867603],
      [41.1075536, -73.7202198],
      [40.9542331, -73.626836],
      [41.3059236, -71.9102222],
      [41.4295993, -71.8031055]
    ]
  },
  {
    region: 'hudsonvalley',
    areaCoordinates: [
      [41.855299, -75.1480261],
      [41.5003468, -75.0171533],
      [41.3585466, -74.7067207],
      [40.9948424, -73.8954893],
      [41.1297713, -73.8868983],
      [41.253781, -73.9720423],
      [41.2971288, -73.9583094],
      [41.3672501, -73.5490687],
      [42.050567, -73.4840137],
      [42.3788927, -74.4957129],
      [41.855299, -75.1480261]
    ]
  },
  {
    region: 'longisland',
    areaCoordinates: [
      [40.7499717, -74.0191892],
      [40.6598984, -74.0514259],
      [40.6867184, -74.0126304],
      [40.7057203, -73.9995841],
      [40.708144, -73.9745828],
      [40.735465, -73.969433],
      [40.7398873, -73.9529535],
      [40.7292212, -73.9313242],
      [40.6841965, -73.894932],
      [40.6961713, -73.8698694],
      [40.6631538, -73.8564185],
      [40.6572939, -73.8615684],
      [40.6145662, -73.8320426],
      [40.5796342, -73.8670615],
      [40.5559013, -74.0380363],
      [40.4710754, -73.7680738],
      [40.9789563, -71.7671888],
      [41.3059236, -71.9102222],
      [40.9542331, -73.626836],
      [40.8733477, -73.7534928],
      [40.8032169, -73.7857651],
      [40.7955499, -73.9114212],
      [40.8010078, -73.9279007],
      [40.8084143, -73.9335656],
      [40.8352045, -73.9340566],
      [40.8565011, -73.9210103],
      [40.8664977, -73.911054],
      [40.8727286, -73.909509],
      [40.8781802, -73.9268468],
      [40.7499717, -74.0191892]
    ]
  },
  {
    region: 'newjersey',
    areaCoordinates: [
      [41.3588614, -74.7065091],
      [41.2898202, -74.8466358],
      [40.9910463, -75.1338722],
      [40.8607154, -75.0587007],
      [40.74435, -75.2125939],
      [40.5693196, -75.2017326],
      [40.1652503, -74.7433366],
      [39.7708647, -75.4742087],
      [39.6113433, -75.5924237],
      [39.5689961, -75.5553738],
      [39.4566931, -75.5595713],
      [38.8183938, -74.9145558],
      [39.6984603, -74.0377895],
      [40.4710043, -73.7684997],
      [40.5559412, -74.0384018],
      [40.6599438, -74.0517172],
      [40.7537419, -74.0171323],
      [40.8781743, -73.9269816],
      [40.915798, -73.9128783],
      [40.9948236, -73.89554],
      [41.3588614, -74.7065091]
    ]
  },
  {
    region: 'westchester',
    areaCoordinates: [
      [41.3672501, -73.5490687],
      [41.2971288, -73.9583094],
      [41.253781, -73.9720423],
      [41.1297713, -73.8868983],
      [40.9948424, -73.8954893],
      [40.9158096, -73.9127706],
      [40.9015389, -73.8612722],
      [40.9086447, -73.8551163],
      [40.9046228, -73.8396668],
      [40.8959295, -73.8382935],
      [40.8733477, -73.7534928],
      [40.9542331, -73.626836],
      [41.1075536, -73.7202198],
      [41.2192107, -73.4867603],
      [41.2935431, -73.5554249],
      [41.3672501, -73.5490687]
    ]
  }
]
