'use client'

import React, { useState } from 'react'

import { ModalEntry } from './types'

type ModalContextValues = {
  modals: ModalEntry[]
  openModal: (payload: ModalEntry) => void
  closeModal: (id: string) => void
}

export const ModalContext = React.createContext<ModalContextValues>({
  modals: [],
  openModal: payload => {},
  closeModal: payload => {}
})

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [openModals, setOpenModals] = useState<ModalEntry[]>([])

  const openModal = (payload: ModalEntry) => {
    setOpenModals(currentModals => [...currentModals, payload])
  }

  const closeModal = (id: string) => {
    setOpenModals(currentModals => currentModals.filter(modal => modal.id !== id))
  }

  return <ModalContext.Provider value={{ modals: openModals, openModal, closeModal }}>{children}</ModalContext.Provider>
}

export default ModalProvider
