import { array, mixed, number, object, string } from 'yup'

export const contentfulSysCommonSchema = object({
  sys: object({
    id: string(),
    type: string(),
    linkType: string()
  })
})

export const contentfulBaseSchema = object({
  metadata: mixed(),
  sys: object({
    space: contentfulSysCommonSchema,
    id: string(),
    type: string(),
    createdAt: string(),
    updatedAt: string(),
    environment: contentfulSysCommonSchema,
    revision: number(),
    contentType: contentfulSysCommonSchema.optional(),
    locale: string()
  }).required()
})

export const richTextSchema = object({
  data: object(),
  content: array(),
  nodeType: string()
})

export const bodyTextSchema = object({
  time: number(),
  blocks: array(),
  version: string()
})
