const ThemeColors = {
  alabaster: '#fcfcfc',
  alertRed: '#d1193e',
  christmasRed: '#b21e2f',
  alertYellow: '#ffda1b',
  americanSilver: '#cecece',
  black: '#010101',
  blackDelicata: '#0b0b0b',
  blueHover: '#2057dd',
  blueHover2: '#2553C5',
  binaryGray: '#101010',
  brightYellow: '#f2c400',
  brilliance: '#fdfdfd',
  charcoal: '#464646',
  citrine: '#f0f200',
  codGray: '#121212',
  codGrayLighter: '#131313',
  drWhite: '#fafafa',
  mist: '#eaeaea',
  lightGray: '#e6e6e6',
  lightGray2: '#bcbcbc',
  lightGray3: '#eeeeee',
  lightGray4: '#656565',
  lightGray5: '#979797',
  lightBlue: '#3970F6',
  mineShaft: '#2c2c2c',
  mourineGray: '#7c7c7c',
  mouseGray: '#505050',
  navy: '#133075',
  newCar: '#2056dc',
  olympus: '#787679',
  romanSilver: '#808390',
  royalBlue: '#417af6',
  sapphire: '#123075',
  turbo: '#e3e500',
  vapor: '#e7e7e7',
  veryLightGray: '#efefef',
  white: '#ffffff',
  zambezi: '#5a5a5a',
  social: {
    twitter: '#00acee',
    facebook: '#3b5998',
    linkedin: '#0e76a8',
    instagram: '#d62976'
  }
}

export default {
  colors: ThemeColors,
  gradients: {
    imageOverlay: `linear-gradient(180deg, transparent 29.66%, ${ThemeColors.mineShaft} 89.13%)`,
    horizontalCarousel: {
      prevButton: `linear-gradient( 270deg, ${ThemeColors.mineShaft}00 0%, ${ThemeColors.mineShaft}96 37.98%, ${ThemeColors.mineShaft}cf 100% )`,
      nextButton: `linear-gradient( 270deg, ${ThemeColors.mineShaft}cf 0%, ${ThemeColors.mineShaft}96 63.02%, ${ThemeColors.mineShaft}00 100% )`
    },
    horizontalList: {
      nextOverlay: `linear-gradient(270deg, ${ThemeColors.mineShaft} 0%, ${ThemeColors.mineShaft}00 100%)`,
      prevOverlay: `linear-gradient(90deg, ${ThemeColors.mineShaft} 0%, ${ThemeColors.mineShaft}00 100%)`
    }
  },
  breakpoints: {
    xxs: '0',
    xs: '375px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
    max: '1920px'
  },
  typography: {
    fontFamily: 'Graphik, sans-serif',
    fontStyle: { normal: 'normal', italic: 'italic' },
    textTransform: { none: 'none', uppercase: 'uppercase' },
    fontWeight: {
      thin: 100,
      extralight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      black: 800,
      super: 900
    },
    headings: {
      h1: {
        fontSize: '2.5rem',
        lineHeight: '3rem'
      },
      h2: {
        fontSize: '1.75rem',
        lineHeight: '2.25rem'
      },
      h3: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem'
      },
      h4: {
        fontSize: '1rem',
        lineHeight: '1.3rem'
      },
      h5: {
        fontSize: '1.125rem',
        lineHeight: '1.125rem'
      },
      h6: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        textTransform: 'uppercase'
      }
    },
    text: {
      regular: { fontSize: '1.25rem', lineHeight: '1.87rem' },
      medium: { fontSize: '1.125rem', lineHeight: '1.438rem' },
      small: { fontSize: '1rem', lineHeight: '1.3rem' },
      smallSpaced: { fontSize: '1rem', lineHeight: '1.3rem' },
      tiny: { fontSize: '0.875rem', lineHeight: '1.138rem' },
      xs: { fontSize: '0.75rem', lineHeight: '0.975rem' }
    },
    interactive: {
      regular: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.5rem'
      },
      medium: {
        fontWeight: 500,
        fontSize: '0.938rem',
        lineHeight: '1.25rem'
      },
      small: {
        fontWeight: 500,
        fontSize: '0.75rem',
        lineHeight: '1rem'
      },
      tiny: {
        fontWeight: 500,
        fontSize: '0.625rem',
        lineHeight: '0.625rem'
      }
    }
  },
  interactive: {
    button: {
      regular: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        padding: {
          md: { basic: '11px 32px 9px', symmetrical: '15px 16px 13px' },
          sm: { basic: '11px 32px 9px', symmetrical: '13px 16px 11px' }
        },
        gap: '8px',
        transform: 'none'
      },
      small: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        padding: {
          md: { basic: '3px 32px 1px', symmetrical: '3px 3px 1px' },
          sm: { basic: '3px 32px 1px', symmetrical: '3px 3px 1px' }
        },
        gap: '2px',
        transform: 'scale(0.7)'
      }
    },
    iconButton: {
      regular: {
        transform: 'none'
      },
      small: {
        transform: 'scale(0.7)'
      }
    }
  },
  adUnitPlaceholder: {
    leaderboard: {
      width: '728px',
      height: '90px'
    },
    superLeaderboard: {
      width: '970px',
      height: '90px'
    },
    mediumRectangle: {
      width: '300px',
      height: '250px'
    },
    halfPage: {
      width: '300px',
      height: '600px'
    },
    smartphoneBanner: {
      width: '320px',
      height: '50px'
    }
  },
  zIndex: {
    base: 0,
    minor: 1,
    dropdown: 10,
    sticky: 20,
    modal: 30,
    overlay: 40,
    alert: 50
  },
  animation: {
    duration: {
      instant: '0ms',
      quick1: '80ms',
      quick2: '120ms',
      moderate1: '180ms',
      moderate2: '260ms',
      gentle1: '320ms',
      gentle2: '400ms'
    },
    easing: {
      productive: {
        standard: 'cubic-bezier(0.2, 0, 0.38, 0.9)',
        entrance: 'cubic-bezier(0, 0, 0.38, 0.9)',
        exit: 'cubic-bezier(0.2, 0, 1, 0.9)'
      },
      expressive: {
        standard: 'cubic-bezier(0.4, 0.14, 0.3, 1)',
        entrance: 'cubic-bezier(0, 0, 0.3, 1)',
        exit: 'cubic-bezier(0.4, 0.14, 1, 1)'
      }
    }
  }
}
