'use client'

import { useContext } from 'react'

import { ModalContext } from './ModalProvider'
import ModalWrapper from './ModalWrapper'

const ModalManager = () => {
  const { modals } = useContext(ModalContext)

  return modals.map(({ id, ...rest }) => <ModalWrapper key={id} id={id} {...rest} />)
}

export default ModalManager
